//UA-251766296-1 rastreamentu 
//290270810 id da propriedade


import React, { useState } from "react";
import { Chart } from "react-google-charts";

function ChartVisitasMes() {

  //console.log(pedidos)
  const [selectedMonth, setSelectedMonth] = useState("all");

  return (
    <div className="w100 column center">
      <div className="w90">
        <label className="row">
          <h5>Total visitas mes</h5>
          <strong className="fs08em">- Em desenvolvimento</strong>
        </label>
        

        <label htmlFor="month-select" >Filtrar por mês:</label>
        <select id="month-select">
          <option value="all">Todos</option>
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </select>
      </div>
      

      
    </div>
  );
}

export default ChartVisitasMes;



/*
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Chart } from "react-google-charts";

const AnalyticsChart = ({ url }) => {
  const [totalVisits, setTotalVisits] = useState(0);
console.log(totalVisits)
  useEffect(() => {
    ReactGA.initialize("UA-251766296-1");
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    ReactGA.event({
      category: "Tenancy",
      action: "Visits",
      label: url,
    });

    const fetchData = async () => {
      const response = await ReactGA.data.ga.get({
        ids: "ga:290270810",
        metrics: "ga:users",
        dimensions: "ga:date",
        "start-date": "30daysAgo",
        "end-date": "yesterday",
        filters: `ga:pagePath==/${url}`,
      });

      const totalVisits = response.totalsForAllResults["ga:users"];
      setTotalVisits(parseInt(totalVisits, 10));
    };

    fetchData();
  }, [url]);

  return (
    <div>
      <h3>Total de Visitas para {url}</h3>
      <Chart
        chartType="BarChart"
        width="100%"
        height="400px"
        data={[["Tenancy", "Visitas"], [url, totalVisits]]}
        options={{
          title: `Total de Visitas para ${url}`,
          vAxis: { title: "Visitas" },
          hAxis: { title: "Tenancy" },
        }}
      />
    </div>
  );
};

export default AnalyticsChart;
*/