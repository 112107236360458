import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
//INPORTAÇOES DE ICONES E IMAGENS

import MenuPainelAdmin from '../partils/AdminMenuPainel'
import { useNavigate } from 'react-router-dom'

import Icon1de3 from '../../acets/img_icon/1de3.svg'
import Icon2de3 from '../../acets/img_icon/2de3.svg'
import Icon3de3 from '../../acets/img_icon/3de3.svg'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminCliente() {
    //CONGIGURAÇÕES=============================
    const config = {
        pageName: 'Venda',
        mainstring: 'nome',
        url: '/api/cliente/',
        url2: '',
        search: '/api/cliente/search/',
        urlOptions1: '/api/produto',
        params: '',
        messegerError: 'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
    //INICIANDO CICLO DE ESTADOS
    useEffect(() => {
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //STATES======================================
    const initialValues = { // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '', estado: '', cidade: '', referencia: '',telefone: '', email: '', senha: '',id_produto:'',quantidade:'',quantidade:'',preco_final:'',id_pedido:'',troco:'0',status:'1',	forma_de_pagamento:'DINHEIRO',observacoes:'',pix:'0'
    }
    //const navigate = useNavigate();

    //const [openNew, setOpenNew] = useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues] = useState(initialValues) // INICIANDO UM STATE
    const [dataInput, setDataInput] = useState([])
    const [datalistItens, setDatalistItens] = useState([])
    const [pedidoId, setPedidoId] = useState()

    const [opemcliente, setOpemcliente] = useState(true)
    const [opemProduto, setOpemProduto] = useState(false)
    const [opemPedido, setOpemPedido] = useState(false)
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        
        //setDataInput /pivo_pedido_cliente
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })
        pedidoId && axios.get("api/pivo_pedido_cliente/"+pedidoId).then(resp=>{
                setDatalistItens(resp.data)
                //console.log(resp.data)
            })
    }
    //console.log(dataInput)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const { name, value } = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]: value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit1(ev) {
        ev.preventDefault(); // cadastradno cliente
        axios.post(config.url, values).then(resp => {
            //setClienteidfront(resp.data.id_cliente)
            rec();
            axios.post("api/pedido/",{status:'1',forma_de_pagamento:'0',troco:'0',observacoes:'null',bairro:'0',rua:'0',numero:'0',complemento:'0',observacoes:'null',id_cliente:resp.data.id_cliente}).then(resp=>{
                setPedidoId(resp.data.id_pedido)
                setOpemcliente(false)
                setOpemProduto(true)
            })
        })
    }
    const navigate = useNavigate();
    function onSubmit2(ev) {
        ev.preventDefault();// cadastrando pedido
        axios.put('api/pedido/pross/auth/'+pedidoId,values).then(() => {
            setValues(initialValues);
            navigate('/adminpedido');
        })
    }
    function onSubmit3(ev) {
        ev.preventDefault();//cadastrando produto id_pedido id_produto
        axios.post('/api/pivo_pedido_cliente', {id_pedido:pedidoId,id_produto:values.id_produto,quantidade:values.quantidade,opcionais:"{}"})
        .then(() => {
            setValues(initialValues);
            rec();
            //setOpemPedido(true)
        })
    }
    //console.log(dataInput)
    // <option key={repsValues.id_produto} value={repsValues.id_produto} >{repsValues.nome}</option>
    const filteredData = dataInput.filter(repsValues => repsValues.status !== 6);
    const optionInput = filteredData.map(repsValues => (
    <option key={repsValues.id_produto} value={repsValues.id_produto}>
        {repsValues.nome}
    </option>
    ));
    //console.log(optionInput)
    const itensLoop = datalistItens.map(repsValues=>
        <div key={repsValues.id_pivo} className="w100 h5 row between linha">
            <label className='w10'>{repsValues.quantidade}</label> 
            <label className='w70'>{repsValues.produto.nome}</label>
            <label className='w20'>R$: {repsValues.produto.preco_final}</label>
        </div>
    )
    var totalItens = datalistItens.reduce(getTotal, 0);//PERCORRENDO o array para somar o total dos itens
    function getTotal(total, item) {//PERCORRE
      return total + (item.quantidade * item.produto.preco_final);
    }



    //COMPONENTES DE LOOP==============================
    return (
        <>
            <section className='h90 column center b15 b30'>
                <AdminHeader props={config.pageName}/>
                <div className="h20 w90 posiabstop row between aligitensstart celular">
                    <MenuPainelAdmin />
                    <div className="w70 row between cell-tamanho">
                        <div className='h20 w100 b2 cont-circ3 sombra column center z10 cell-tamanho'>
                            <div className='w90 h20'>
                                <div className='w100'>
                                    <h5>Nova {config.pageName}</h5>
                                </div>
                                {opemcliente&&
                                <form onSubmit={onSubmit1} autoComplete='off'>
                                    <img src={Icon1de3} className="w30" alt="1 de 3"></img>
                                    <h5>Cadastro de cliente</h5>
                                    <div>
                                        <label>Nome:</label>
                                        <input type="text" name="nome" onChange={onChange} value={values.nome} autoComplete='off'></input>
                                    </div>
                                    <div className='cell-margin5 ma10b0'>
                                        <input type="submit" value="Proximo" style={{backgroundColor: TenancyColor().backgroundColor}}></input>
                                    </div>
                                </form>
                                }
                                { opemProduto&&
                                <div className='w100'>
                                    <img src={Icon2de3} className="w30" alt="2 de 3"></img>
                                    <h5 className='w100'>Cadastro de Produtos</h5>
                                    <form className='w100 Icon3de3' onSubmit={onSubmit3}>
                                        <div className='row between w100'>
                                            <div className='w30'>
                                                <label>Produto:</label>
                                                <select type="text"  name="id_produto"  onChange={onChange} >
                                                    <option>Selecione o produto</option>
                                                    {optionInput}              
                                                </select>
                                            </div>
                                            <div className='w30'>
                                                <label>Quantidade:</label>
                                                <input type="number" name="quantidade" onChange={onChange} value={values.quantidade}></input>
                                            </div>
                                            
                                            <div className='w30'>
                                                <label>Ação:</label>
                                                <input type="submit" value="Adcionar Item" style={{backgroundColor: TenancyColor().backgroundColor}}></input>
                                            </div>
                                        </div>
                                    </form>
                                    <div className='w100 column ma10b0'>
                                       {itensLoop}
                                       <h5 className='w100 fim'>Total R$:{totalItens}</h5>
                                    </div>
                                    <button className='w100 ma25b0' onClick={()=>setOpemPedido(true) & setOpemProduto(false)} style={{backgroundColor: TenancyColor().backgroundColor}}>Proximo</button>
                                    
                                </div>
                                }
                                

                                {opemPedido&&
                                <form onSubmit={onSubmit2} autoComplete='off'>
                                    <img src={Icon3de3} className="w30" alt="3 de 3"></img>
                                    <div className='row between w100'>
                                    <h5 className='w100'>Cadastro de pedido</h5>
                                        <div className='w30'>
                                            <label> status:</label>
                                            <select name="status" onChange={onChange} >
                                                <option value="1"> Aberto</option>
                                                <option value="2"> Em produção</option>
                                                <option value="3"> Finalizado</option>
                                                <option value="4"> Cancelado</option>
                                            </select>
                                        </div>
                                        <div className='w30'>
                                            <label>Pagamento:</label>
                                            <select type="text"  name="forma_de_pagamento"  onChange={onChange} >
                                                <option value={"DINHEIRO"}> Dinheiro</option>
                                                <option value={"CART.CRED"}> Cartao de credito</option>
                                                <option value={"CART.DEBT"}> Cartao de Debito</option>
                                                <option value={"PIX"}> Pix</option>                                   
                                            </select>
                                        </div>
                                        <div className='w30'>
                                            <label>Troco:</label>
                                            <input type="text" name="troco" onChange={onChange} value={values.troco}></input>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Observacoes:</label>
                                        <input type="text" name="observacoes" onChange={onChange} value={values.observacoes} autoComplete='off'></input>
                                    </div>
                                    <div className='cell-margin5 ma10b0'>
                                        <input type="submit" value="Finalizar venda" style={{backgroundColor: TenancyColor().backgroundColor}}></input>
                                    </div>
                                </form>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h75 w100 b17">

                </div>
            </section>
        </>
    )
}

