import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import GerarStoryInstagram from '../partils/pluginsLocal/GerarStoryInstagram'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminGerarStory(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Gerar Story',
        mainstring:'nome',
        url:'/api/pixAPI/',
        url2:'',
        search:'/api/pixAPI/search/',
        urlOptions1:'/api/pixAPI',
        
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '',ingredientes:'', quantidade: '',preco_final: '',status: '',validade: '',preco_custo:'', id_produto_categoria: '', id_cardapio:'3'
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        /*axios.get(config.url).then(resp=>{
            setData(resp.data)})
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })*/
    }
    
    //COMPONENTES DE LOOP==============================
  
    
    
    return(
        <>
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" />
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}}/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='column between w100 h100'>
                              <GerarStoryInstagram/>
                            </div>
                           
                            
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

