import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import IconSaberto from '../../acets/img_icon/ico_s_aberto.svg'
import IconScancel from '../../acets/img_icon/ico_s_cancel.svg'
import IconSfinalizado from '../../acets/img_icon/ico_s_finalizado.svg'
import IconSprod from '../../acets/img_icon/ico_s_prod.svg'
import IconSpromo from '../../acets/img_icon/ico_s_promo.svg'

export default function IcoStatus(props){

  function Icoimage(i) {
    const a = i.props
    //console.log(a)
    switch (a) {
      case 1:
        return IconSaberto;
      case 2:
        return  IconSprod;
      case 3:
        return IconSfinalizado;
      case 4:
        return IconScancel;
      case 5:
        return IconSpromo;
      case 6:
        return IconScancel;
    
      default:
        return 'nao pegou';
    }
  }
    return(
      <img src={Icoimage(props)} alt='imagem'/>
    )
}