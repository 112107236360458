//import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

//COMPONENTES PAGES
import Login from './components/pages/Login'
import Home from './components/pages/Home'
import Carrinho from './components/pages/Carrinho'
import Painel from "./components/pages/AdminPainel";
import AdminProduto from "./components/pages/AdminProduto";
import AdminFrete from "./components/pages/AdminFrete";
import AdminCliente from "./components/pages/AdminCliente"
import PageIndex from "./components/pages/PageIndex"
import AdminProdutoCategoria from "./components/pages/AdminProdutoCategoria";
import AdminDadosEmpresa from "./components/pages/AdminDadosEmpresa"
import AdminPedidos from "./components/pages/AdminPedido"
import AdminVendaRapida from "./components/pages/AdminVendaRapida"
import AdminEstoqueProduro from "./components/pages/AdminEstoquProduto";
import AdminLeads from "./components/pages/AdminLead"
import TeancyAuth from "./components/midllers/TenancyAuth";
import AdminImagem from "./components/pages/AdminImagem"
import GerarStoryInstagram from "./components/partils/pluginsLocal/GerarStoryInstagram";
import AdminEntregador from "./components/pages/AdminEntregador"

import axios from 'axios';
import PrivateRouter from "./components/midllers/PrivateRouter";
import AdminPix from "./components/pages/AdminPix";
import AdminGerarStory from "./components/pages/AdminGerarStory";
import PublicPedidoDetails from "./components/pages/PublicPedidoDetails";
import { useEffect, useState } from "react";
import Pedido from "./components/pages/Pedido";
import AdminFeature from "./components/pages/AdminFeature";
import AdminFinanceiroTenancy from "./components/pages/AdminFinanceiroTenancy";
import AdminAssistenteInteligente from "./components/pages/AdminAssistenteInteligente";

//COMPONENTES PARTLS
//import Menu from "./components/partils/Menu";

//sessionStorage.getItem('keyItemNameg')
const tlocal = localStorage.getItem('keyItemNameg')
const tenancylocal = localStorage.getItem('ClienteTenancy')
axios.defaults.baseURL = "https://cardapio360.com.br/app/";
//axios.defaults.baseURL ="http://192.168.0.122:3002"   
//axios.defaults.baseURL ="https://melhorcatalogo.online/"
//axios.defaults.baseURL ="http://localhost:3002"
//axios.defaults.baseURL ="http://10.0.0.57:3002"
//axios.defaults.baseURL ="http://192.168.0.120:3002" 
axios.defaults.headers.common['token'] = tlocal;
axios.defaults.headers.common['id_tenancy'] = tenancylocal;

//axios.defaults.withCredentials = true;
function App() {

  

  return (
    <BrowserRouter>

    <Routes>
      <Route path="/" element={<PageIndex />} />

      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/carrinho" element={<Carrinho />} />
      <Route path="/pedido" element={<Pedido/>} />
      <Route path="/loja/:id" element={<TeancyAuth />} />
      <Route path="/gerarStory" element={<GerarStoryInstagram/>}/>
      <Route path="/pedido/CheckPedidoAuth/:id" element={<PublicPedidoDetails/>}/>

      <Route element={<PrivateRouter/>}>
        <Route path="/adminentregador" element={<AdminEntregador />} />
        <Route path="/adminassistenteinteligente" element={<AdminAssistenteInteligente />} />
        <Route path="/painel" element={<Painel />} />
        <Route path="/adminproduto" element={<AdminProduto />} />
        <Route path="/admingerarstory" element={<AdminGerarStory />} />
        <Route path="/admincliente" element={<AdminCliente />} />
        <Route path="/admincategoriaproduto" element={<AdminProdutoCategoria />} />
        <Route path="/admindadosempresa" element={<AdminDadosEmpresa />} />
        <Route path="/adminpedido" element={<AdminPedidos />} />
        <Route path="/adminvendarapida" element={<AdminVendaRapida />} />
        <Route path="/adminlead" element={<AdminLeads />} />
        <Route path="/adminpix" element={<AdminPix />} />
        <Route path="/adminprodutoestoque" element={<AdminEstoqueProduro />} />
        <Route path="/adminimagen" element={<AdminImagem />} />
        <Route path="/adminfrete" element={<AdminFrete />} />
        <Route path="/adminfeature" element={<AdminFeature />} />
        <Route path="/adminfinanceirotenancy" element={<AdminFinanceiroTenancy />} />
        
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
