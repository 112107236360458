import { useEffect, useRef, useState } from "react";

    

export default function ContadorRegressivo(){

//mes dia e ano
const [days,setDays] = useState()
const [hours,setHours] = useState()
const [minutes,setMinutes] = useState()
const [seconds,setSeconds] = useState()

const interval = useRef();
useEffect(()=>{

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    let count_down = new Date('07/20/2023 01:01:01').getTime();
    
    interval.current = setInterval(() => {
    
        let now = new Date(Date.now()).getTime();
        let diff = count_down - now;
        setDays(Math.floor(diff / day)) 
        setHours(Math.floor(diff % day / hour))
        setMinutes( Math.floor(diff % hour / minute))
        setSeconds(Math.floor(diff % minute / second))
    }, 1000);
     // move clean up function to here:
  return () => {
    clearInterval(interval.current);
    interval.current = null;
  };   

},[])
    
    
    return(
        
     <div className="w100 row between h15">

            <div className="column center h10 w20 cont-circ3">
                <label className="row center w100 h8 border-black fb fs1p3em">{days < 0 ? 0 : days}</label>
                <label className="cell-text-smile08 fb fs08em ma5 c1">Dia(s)</label>
            </div>
            <div className="column center h10 w20 cont-circ3">
                <label className=" row center w100 h8 border-black fb fs1p3em" >{hours < 0 ? 0 : hours}</label>
                <label className="cell-text-smile08 fb fs08em ma5 c1">Hora(s)</label>
            </div>
            <div className="column center h10 w20 cont-circ3">
                <label className=" row center w100 h8 border-black fb fs1p3em">{minutes < 0 ? 0 : minutes}</label>
                <label className="cell-text-smile08 fb fs08em ma5 c1">Minuto(s)</label>
            </div>
            <div className="column center h10 w20 cont-circ3">
                <label className="row center w100 h8 border-black fb fs1p3em">{seconds < 0 ? 0 : seconds}</label>
                <label className="cell-text-smile08 fb fs08em ma5 c1">Segundo(s)</label>
            </div>
     </div>
    )
}