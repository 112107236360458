import { Link } from "react-router-dom";

import ImgAnimationHeader from "../../acets/img_site/header-img.svg"
import ImgAnimationCarrinho from "../../acets/img_site/carrinnho.svg"
import ImgCellContato from "../../acets/img_site/imgrelogiocontato.svg"

import IconMenuPreco from"../../acets/img_site/precos.svg"
import IconMenuHome from"../../acets/img_site/home.svg"
import IconMenuVantagens from"../../acets/img_site/servicos.svg"
import IconMenuContato from"../../acets/img_site/contato.svg"
import IconMenuSobre from"../../acets/img_site/sobre.svg"
import IconMenuLogin from"../../acets/img_site/login.svg"

import ImgIconCheck from "../../acets/img_site/checked.svg"
import ImgIconNocheck from "../../acets/img_site/nochecked.svg"
import ImgCell from "../../acets/img_site/cell.png"
import LogoCell from "../../acets/img_icon/logocellsite.svg"
//import ImgWpp from "../../acets/img_site/wpp.jpg"
import ImgWpp from "../../acets/img_icon/wpp.png"
import ImgNewWpp from "../../acets/img_site/newwpp.svg"
import { useEffect, useState } from "react";
import axios from "axios";
import Contador from "../partils/ContatorRegressivo"



export default function PageIndex(){
   
const [terms,setTerms]=useState(true)
const [newlaster,setnewlaster]=useState(false)

const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
    nome: '',telefone:'',email:'',porte:'MEI'
}
const [values, setValues]=useState(initialValues) // INICIANDO UM STATE

useEffect(() => {
    const timeoutId = setTimeout(() => {
            setnewlaster(true)
    }, 20000); // Tempo em milissegundos para o timeout
    return () => clearTimeout(timeoutId);
  }, []);


function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
    const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
    setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
}
function onSubmit(ev){
        ev.preventDefault();
        //nome: '',telefone:'',email:'',porte:'MEI'
        //const inputIsChecked = document.getElementById('vehicle1');
        //console.log(inputIsChecked)
        if(values.nome,values.telefone===""){
            alert("ATENÇÃO. Preencha os campos de nome, telefone, aceite de email e porte obrigatoriamente")
        }
        else{
            axios.post('/api/leads',values).then(()=>{
                setValues(initialValues);
                alert("Dados enviados com sucesso, você receberá um email com as informações de login em breve!")
            })
        }
}
function onSubmitNewLasteer(ev){
    ev.preventDefault();
    if(values.email===""){
        alert("ATENÇÃO. Preencha os campos de nome, telefone, aceite de email e porte obrigatoriamente")
    }
    else{
        axios.post('/api/leads',values).then(()=>{
            setValues(initialValues);
            alert("Inscrição realizada com sucesso!")
            setnewlaster(false)
        })
    }
    
}
  /*
window.Notification.requestPermission(permission=>{
    if(permission==='granted'){
        new window.Notification('Habits',{
            body:'Textoh'
        })
    }
})
*/
//---------------------------------------------------------------------------------
    return(
      <>
        <header className="w100 h97 bheader column center site-header-img">
            {terms&&
            <div className="w100 h40 posifixedfim0 fundo-opaco z7 column center">
                <div className="column center b2 w80 cell-tamanho90 cont-circ8">
                    <h5>Aceitar termos e cookies?</h5>
                    <label className="w90 cell-tamanho90 cen fs08em ">Os
                    <Link to="" className="fb"> termos e cookies </Link>
                     são elementos importantes da política de privacidade na internet, pois garantem transparência e segurança no uso dos dados dos usuários</label>
                    
                    <div className="row center p10">
                        <button className="btn05 ml10">Recusar</button>
                        <button className="ml10" onClick={()=>setTerms(false)}>Aceitar</button>
                    </div>
                </div>
            </div>
            }
            {newlaster&&
            <div className="w100 h100 posifixedfim0 fundo-opaco z7 column center">
                <form className="w80 b2 cont-circ8 p10 cell-tamanho90 column center" onSubmit={onSubmitNewLasteer}>
                    <div className="w90">
                        <h5>Participe da nossa newsletter</h5>
                        <label>Receba depoiomentos de empreendedores que aumentaram os seus lucros em até <strong>20%</strong>, receba dicas e estratégias de vendas! <strong>Grátis</strong>.</label>
                        <div>
                            <input type="email" name="email" placeholder="Email: ex joao@gmail.com" onChange={onChange} value={values.email}></input>
                        </div>
                        <div>
                            <input type="submit" value="Enviar"></input>
                        </div>
                        <div className="w100 column center">
                            <label className="p10 pp" onClick={()=>setnewlaster(false)}>Cancelar</label>
                        </div>
                        
                    </div>
                    
                </form>
            </div>
            }
            <div className="w80 wmax1200 h70 b row center">
                <nav className="w100 h5 row between">
                    <ul className="w60 row between center h5 celular cell-none">
                        <a href="" className="cell-tamanho">
                            <li className="cell-tamanho cell-bg cell-margin5 home row center">
                            <img src={IconMenuHome} alt="icone de casa" className="mac0l8"></img>
                                <label className="c1 pp">
                                    Home
                                </label>
                            </li>
                        </a>
                        <a href="#vantagens" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                                <img src={IconMenuVantagens} alt="icone de engrenagens" className="mac0l8"></img>
                                <label  className="c1 pp">
                                    Vantagens
                                </label>
                            </li>
                        </a>
                        <a href="#contato" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                                <img src={IconMenuContato} alt="icone de uma carta" className="mac0l8"></img>
                                <label className="c1 pp">
                                    Contato
                                </label>
                            </li>
                        </a>
                        <a href="#planos" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                                <img src={IconMenuPreco} alt="icone de cartao de credito/debito" className="mac0l8"></img>
                                <label className="c1 pp">
                                    Planos
                                </label>
                            </li>
                        </a>
                        <a href="#sobre" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                            <img src={IconMenuSobre} alt="icone de lupa representando ajuda" className="mac0l8"></img>
                                <label className="c1 pp"> 
                                    Sobre
                                </label>
                            </li>
                        </a>
                        
                        
                        
                    </ul>
                    <Link to="/login" className="cell-tamanho  cont-circ8 p7 borda1 cell-none b2">
                        <li className="cell-tamanho  cell-bg cell-margin5 row center">
                            <img src={IconMenuLogin} alt="icone de um avatar/boneco ilustrativo"className="mac0l8"></img>
                            <label className="c1 pp"> 
                                Login
                            </label>
                        </li>
                    </Link>
                </nav>
                

                <div className="w40 h80 row start cell-tamanho">
                    <div className="w70 cell-tamanho h40 ">
                       <div className="desktop-none w100 column">
                            <Link to="/login" className="w100 h5 fb">Login</Link>
                       </div>
                        <h1 className="cell-text-title c1 ma10b0 fs2em">Cardápio 360</h1>
                        
                        <h2 className="c1 fs1p3em w100 ">Tenha o seu próprio sistema de delivery com a sua marca e sem concorrência interna! venha automatizar os pedidos!</h2>
                        <div className="w100 row between ma10b0">
                            <div className="w49 cell-tamanho">
                                <a href="#contato">
                                    <button className="btn07">
                                        Grátis 20 Dias
                                    </button>
                                </a>
                            </div>   
                            <div className="w49 cell-tamanho">
                                <Link to="/loja/starburguer">
                                    <button className="btn08 fb">Ver Loja</button> 
                                </Link>
                            </div> 
                        </div>
                        

                        <label className="fs08em c5 w100 pcb10 ">PROMOÇÃO ENCERRA EM:</label>
                        <Contador/>
                    </div>
                    
                </div>
                <div className="w60 h70 column center cell-none" data-aos="fade-left">
                  <img src={ImgAnimationHeader} alt="pc" className="w100"></img>
                </div>
            </div>
        </header>
        <section className="w100 h30 b27 column center pcb50" id="sobre"data-aos="zoom-in">
            <div className="w80 wmax1200 h30 row center celular ">
                <div className="w100 h50 row between cont-circ10" >
                    <img src={ImgCell} alt="cell" className="w20 cell-tamanho90 " ></img>
                    <img src={ImgAnimationHeader} alt="cell" className="w20 cell-tamanho90 desktop-none cell-none" ></img>
                    <div className="w70 cell-tamanho">
                        <h4 className="cell-margin10">Seu micro app para Delivery</h4>
                        <h5 className="ma10b0 cell-content"></h5>
                        <label className="ma10b0 c1 fs1p3em w100 cell-content cell-text-smile-weight c6">Com o nosso sistema de delivery, você tem total flexibilidade para escolher como receber seus pagamentos, seja online ou no momento da entrega. Se optar por receber pagamentos online com o Pix, você poderá aproveitar uma taxa de apenas 1,9%, o que resulta em uma redução significativa nos custos de suas transações financeiras dentro de aplicativos. Além disso, é possivel a opção de pagamento com cartão de crédito ou débito na maquininha, onde as taxas variam entre 4% e 7%.
                        </label>

                        <div className="w30 cell-tamanho ma10b0">
                            <Link to="/loja/starburguer">
                                <button className="btn08 fb">Ver Loja Modelo</button> 
                            </Link>
                        </div> 
                        <label className="fs1em w80 cell-text-smile-weight c6  ">Transforme sua experiência de pedidos com nosso SaaS de cardápio online. Envie pedidos diretamente para o WhatsApp, personalize seu cardápio e ofereça a melhor experiência aos seus clientes. Acessível e fácil de usar. delivery com o seu nome <strong>www.sualoja.com.br</strong> Experimente agora!.</label>
                    </div>
                    
                </div>
            </div>
        </section>

        <section className="w100 h80 bg-wpp column center pcb50" id="vantagens">
        <div className="w80 wmax1200 pcb20 row center" data-aos="fade-right">
                <h4 className="w100 cell-text-subtitle">Menos conversas, mais vendas.</h4>
                <div className="w50 column cell-tamanho">
                    <h5 className="w100 fb">ANTES</h5>
                    <label className="cell-tamanho roboto fs1p3em cell-text-smile">Diversas mensagens para fechar o pedido, falhas de comunicação, demora em receber respostas, funcionario ocupado exclusivamente nas atividades de atendimento online...</label>
                    <h5 className="w100 cell-tamanho fb">DEPOIS</h5>
                    <label className="cell-tamanho roboto fs1p3em cell-text-smile">Envie o link para o seu cliente, receba o pedido fechado direto no seu WhatsApp em uma unica mensagem.</label>
                    <img src={ImgNewWpp} alt="new wpp" className="w100"></img>
                </div>
                <div className="w50">
                    
                </div>
        </div>
        </section>

        <section className="w100 h50 center column  b27 pcb20" id="contato">
                <div className="w80 h40 cell-tamanho80  cont-circ8 column center b2 sombra wmax1200">
                    <form className="w50 pcb50 cell-tamanho80" onSubmit={onSubmit} autoComplete='off'>
                        <h4>CADASTRO</h4>
                        <label className="c5">Nome completo:</label>
                        <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                        <label className="c5">Numero de telefone com DDD:</label>
                        <input type="number" name="telefone" onChange={onChange} value={values.telefone}></input>
                        <label className="c5">Endereço de e-mail:</label>
                        <input type="email" name="email" onChange={onChange} value={values.email}></input>
                        <label className="c5">Porte do seu empreendimento:</label>
                        <select name="porte" onChange={onChange} value={values.porte}>
                            <option value="MEI">MEI - Microempreendedor individual</option>
                            <option value="ME">ME - Microempresa</option>
                            <option value="EPP">EPP - Empresa de Pequeno Porte</option>
                            
                        </select>
                        <div className="pcb10">
                            <input type="checkbox" id="vehicle1" className="pp"></input>
                            <label className="pp c5" htmlFor="vehicle1">Aceito receber email de contato</label>
                        </div>
                        <button type="submit" value="Continuar com o cadastro" className="btn06 w100"> Continuar</button>

                    </form>
                
            </div>
        </section>
        
        <section className="w100 h110 center column b27" id="planos" >
            <div className="w80 wmax1200 h80 pcb50" data-aos="zoom-in">
                <h4 className="w100 cell-text-subtitle pcb20">PLANOS</h4>
                
                
                <div className="w100 h70 row between celular">
                    <div className="w25 h70 b2 cont-circ7 column between sombra cell-tamanho cell-margin10">
                        <h4 className="w100 cen pcb20">Single</h4>
                        <label className="w80 fs08em pcb20">Ideal para você que esta crescendo e precisa de agilidade para receber pedidos.</label>
                        <div className="row w90 cell-padding4">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Link para sua loja</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Painel de admin</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de categoria</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de produtos</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Meios de Pagamento externo</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label className="">Pedidos para o WhatsApp</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconNocheck} alt="no" className="p7 cell-padding4"></img>
                            <label>Relatórios</label>
                        </div>
                       
                        <div className="row w90">
                            <img src={ImgIconNocheck} alt="no" className="p7 cell-padding4"></img>
                            <label>Painel de pedidos autom.</label>
                        </div>
                         <div className="row w90">
                            <img src={ImgIconNocheck} alt="no" className="p7 cell-padding4"></img>
                            <label>Aplicativo Android</label>
                        </div>
                            <div className="row center w100 pcb20">
                                <label className="fs08em sublinhado">R$:70,00 por :</label>
                                <h4 className="">R$:39</h4>
                                <label>,90</label>
                                <label className="fs06em">/Mês</label>
                            </div>
                            
                            <div className="w80 column fb fs08em ma10b0 pp">Termos e condições</div>
                        <a href="#contato" className="w100"><button className="w100 h5 btn06">Contratar</button></a>
                    </div>
                
                    <div className="w25 h70 b2 cont-circ7 column between sombra cell-tamanho cell-margin10">
                        <h4 className="w100 cen pcb20">Premium</h4>
                        <label className="w80 fs08em pcb20">Para você que precisa melhorar a gestão de todo o negócio.</label>
                        <div className="row w90 cell-padding4">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Link para sua loja</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Painel de admin</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de categoria</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de produtos</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Meios de Pagamento externo</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label className="">Pedidos para o WhatsApp</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Relatórios</label>
                        </div>
                       
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Painel de pedidos autom.</label>
                        </div>
                         <div className="row w90">
                            <img src={ImgIconNocheck} alt="no" className="p7 cell-padding4"></img>
                            <label>Aplicativo Android</label>
                        </div>
                            <div className="row center w100 pcb20">
                                <label className="fs08em sublinhado">R$:300,00 por :</label>
                                <h4 className="">R$:219</h4>
                                <label>,99</label>
                                <label className="fs06em">/Mês</label>
                            </div>
                            <div className="w80 column fb fs08em ma10b0 pp">Termos e condições</div>
                        <a href="#contato" className="w100"><button className="w100 h5 btn06">Contratar</button></a>
                    </div>

                    <div className="w25 h70 b2 cont-circ7 column between sombra cell-tamanho cell-margin10">
                        <h4 className="w100 cen pcb20">Business</h4>
                        <label className="w80 fs08em pcb20">Para você que quer se destacar entre os concorrentes e oferecer um serviço personalizado.</label>
                        <div className="row w90 cell-padding4">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Link para sua loja</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Painel de admin</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de categoria</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Cadastro de produtos</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Meios de Pagamento externo</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label className="">Pedidos para o WhatsApp</label>
                        </div>
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Relatórios</label>
                        </div>
                       
                        <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Painel de pedidos autom.</label>
                        </div>
                         <div className="row w90">
                            <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                            <label>Aplicativo Android</label>
                        </div>
                            <div className="row center w100 pcb20">
                                <label className="fs08em sublinhado">R$:999,00 por :</label>
                                <h4 className="">R$:799</h4>
                                <label>,90</label>
                                <label className="fs06em">/Mês</label>
                            </div>
                            <div className="w80 column fb fs08em ma10b0 pp">Termos e condições</div>
                        <a href="#contato" className="w100"><button className="w100 h5 btn06">Contratar</button></a>
                    </div>

                </div>
            </div>
        </section>

        <section className="w100 h40 b7">

        </section>
        <div className="posifixedfim20 rigth20 h10 w10 row end cell-tamanho-metade30">
            <a href="https://api.whatsapp.com/send?phone=5577999152909&text=Olá, quero saber mais sobre o cardapio ;D" className="w100 row end"><img src={ImgWpp} alt="ok" className="w100 cell-padding4"></img></a>
        </div>
        <footer className="w100 h5 b1 row center">
            <label className="c6 cen">Cardapio 360 - 2022 Copyright - todos os direitos reservados </label>
        </footer>
      </>
    )
}

/*

<!-- Google tag (gtag.js) --><script async src="https://www.googletagmanager.com/gtag/js?id=G-3YTVCNJ4N1"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-3YTVCNJ4N1');</script>


*/