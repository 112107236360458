import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
import IcoStatus from '../partils/IcoStatus'
import { Link, useParams } from 'react-router-dom'
import TenancyColor from '../midllers/TenancyColor'
//INPORTAÇOES DE ICONES E IMAGEN

export default function PublicPedidoDetails(){
    const [data,setData]=useState([])
    const { id } = useParams()
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Pedido Publico',
        mainstring:'nome',
        url:'/api/checkpedido/',
        url2:'',
        search:'/api/cliente/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
       
    }
    //FUNÇÕES=====================================
    
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
       axios.get(config.url+id).then(resp=>{
        setData(resp.data)
        //console.log(data)
       })
    }
    //COMPONENTES DE LOOP==============================
    //var total = data.reduce(getTotal, 0);//PERCORRENDO o array para somar o total dos itens
    //function getTotal(total, item) {//PERCORRE
    //  return total + (item.preco_final);
    //}
    const finalRender = data.map((respLoop) => {
      let opcionaisObj = {};
    
      if (respLoop.opcionais) {
        try {
          opcionaisObj = JSON.parse(respLoop.opcionais);
        } catch (error) {
          console.log('Erro ao fazer o parse do JSON:', error);
          return null; // Ou outra ação apropriada para lidar com o JSON inválido
        }
      }
    
      let precoTotalProduto = respLoop.preco_final;
    
      for (const opcional in opcionaisObj) {
        const precoOpcional = opcionaisObj[opcional].preco_opcional;
        if (!isNaN(precoOpcional)) {
          precoTotalProduto += precoOpcional;
        }
      }
    
      return (
        <div className='row between w100 ma5 bbot1' key={respLoop.id_pivo}>
          <label className='w10 '>{respLoop.quantidade}</label>
          <label className='w70 '>{respLoop.produto.nome.charAt(0).toUpperCase() + respLoop.produto.nome.slice(1)}</label>
          <label className='w20 '>R$:{precoTotalProduto}</label>
        </div>
      );
    });
      
    const precoTotal = data.reduce((total, item) => {
      let opcionaisObj = {};
    
      if (typeof item.opcionais === 'string' && item.opcionais.trim() !== '') {
        try {
          opcionaisObj = JSON.parse(item.opcionais);
        } catch (error) {
          console.log('Erro ao fazer o parse do JSON:', error);
        }
      }
    
      let precoTotalProduto = item.preco_final;
    
      for (const opcional in opcionaisObj) {
        const checked = opcionaisObj[opcional].checked;
        const precoOpcional = opcionaisObj[opcional].preco_opcional;
    
        if (checked && !isNaN(precoOpcional)) {
          precoTotalProduto += precoOpcional;
        }
      }
    
      return total + precoTotalProduto;
    }, 0);
    
    const statusDoPedido = data[0]?.pedido.status;
    const TenancyDoPedido = data[0]?.pedido.id_tenancy; 
    console.log(data[0])
    return(
    <div className='column center w100'>
        <div className='w40 h100 column center cell-tamanho'>
            <h4>{TenancyDoPedido}</h4>
            <h5 className='w90 '>Acompanhe seu pedido</h5>
            <div className='h5 w90 row between borda1 cont-circ7'>
            <IcoStatus props={statusDoPedido}/> 
            {statusDoPedido === 1 && <label>Em preparação</label>}
            {statusDoPedido === 2 && <label>Em produção </label>}
            {statusDoPedido === 3 && <label>Finalizado</label>}
            {statusDoPedido === 4 && <label>Cancelado</label>}
            <button onClick={() => window.location.reload()} style={{backgroundColor: TenancyColor().backgroundColor}}>Recarregar</button>
            </div>

            <h5 className='w90 '>Detalhes</h5>
            <div className='w90 h20 row column b2 cont-circ7 borda1'>
                <div className='w90 row between ma25b0'>
                    <label className='w10 '>Qnt</label>
                    <label className='w70 '>Nome</label>
                    <label className='w20 '>Total</label>
                </div>
                <div className='w90 ma10b0'>
                    {finalRender}
                </div>
            
            </div>
            <div className='w100 column'>
                <div className='w90 row between'>
                <span className='w90 fs1em m5'>Pedido com frete incluso de R$:{data[0]?.pedido.frete}</span>
                    <h5>Total do pedido:</h5>
                    <h5>R$:{(data[0]?.pedido.frete + (precoTotal || 0)).toFixed(2)}</h5>
                </div>
                
            </div>
            <div className='w90'>
                <Link to="/pedido" className='c3'>
                    <button className='w100 column center h5 ma10b0 pp cont-circ15' style={{backgroundColor: TenancyColor().backgroundColor}}>
                        Voltar
                    </button>
                </Link>
            </div>
            
        </div>
    </div>
    )
}

