import React, { useState } from "react";
import { Chart } from "react-google-charts";

function ChartCampanhaInstagram({ pedidos }) {

  //console.log(pedidos)
  const [selectedMonth, setSelectedMonth] = useState("all");

  return (
    <div className="w100 column center">
      <div className="w90">
        <label className="row">
          <h5>Campanha Instagram</h5>
          <strong className="fs08em">- Em desenvolvimento</strong>
        </label>
        

        <label htmlFor="month-select" >Filtrar por mês:</label>
        <select id="month-select">
          <option value="all">Todos</option>
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </select>
      </div>
      

      
    </div>
  );
}

export default ChartCampanhaInstagram;
