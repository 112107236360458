import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminAssistenteInteligente(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Assistente',
        mainstring:'nome',
        url:'/api/chatassistente/',
        url2:'',
        search:'/api/pixAPI/search/',
        urlOptions1:'/api/pixAPI',
        
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        pergunta: '',especialista:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState()
    const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        /*axios.get(config.url).then(resp=>{
            setData(resp.data)})
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })*/
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    //==============================
    function savedata(){
        axios.post(config.url,values).then(resp=>{
            console.log(resp.data)
            setData(resp.data)
        })
    }
    //console.log(values)
    
    return(
        <>
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Chat {config.pageName}</h5>
                        <form className=' w90 row center' autoComplete='off'>
                           
                        </form>
                        <div className='w90 h20 column'>
                            <div className='column between w100 h50'>
                              <label className='w100'>Selecione a especialidade:</label>
                              <select name='especialista' onChange={onChange} value={values.especialista}>
                                <option value="especialista">Contador - Antonio</option>
                                <option value="marketing">Marketing - Rafael</option>
                                <option value="advogado">Advogado - Romilson</option>
                              </select>
                              <label className='w100 ma10b0'>Resposta:</label>
                              <pre className='b15 w100 h20 overflowY ' >
                                {data?data:<label>Aguarde...</label>}
                              </pre>
                              <label className='w100 ma10b0'>Faça uma Pergunta sobre estrategias de negócio, no minimo 3 palavras:</label>
                              <label className='w100 ma10b0'>Exemplos:</label>
                              <div className='w100 row'>
                                <label onClick={()=>setValues({ ...values, pergunta:'Olá me de 4 dicas de como vender mais' })} className='b27 column center cont-circ7 fs06em fb p5 mac0l8 pp'>
                                    Olá me de 4 dicas de como vender mais
                                </label>
                                <label onClick={()=>setValues({ ...values, pergunta:' Como gerar gatilho de escassez?' })} className='b27 column center cont-circ7 fs06em fb p5 mac0l8 pp'>
                                    Como gerar gatilho de escassez?
                                </label>
                                <label onClick={()=>setValues({ ...values, pergunta:'Como elaborar uma promoção?' })} className='b27 column center cont-circ7 fs06em fb p5 mac0l8 pp'>
                                    Como elaborar uma promoção?
                                </label>
                                <label onClick={()=>setValues({ ...values, pergunta:'Qual será a próxima data comemorativa' })} className='b27 column center cont-circ7 fs06em fb p5 mac0l8 pp'>
                                    Qual será a próxima data comemorativa?
                                </label>
                                <label onClick={()=>setValues({ ...values, pergunta:'Como aumentar os lucros do meu delivery?' })} className='b27 column center cont-circ7 fs06em fb p5 mac0l8 pp'>
                                    Como aumentar os lucros do meu delivery?
                                </label>
                              </div>
                              

                              <input type='text' placeholder='Digite aqui' value={values.pergunta} name='pergunta' onChange={onChange}>

                              </input>
                              <div className='cell-margin5 ma10b0 w100'>
                                {values.especialista&&values.pergunta.length>15?
                                <button onClick={()=>savedata()} style={{backgroundColor: TenancyColor().backgroundColor, width:'100%'}}>Enviar Mensagem</button>:
                                <button onClick={()=>alert('Preencha o especialiste e descreva a pergunta com no minimo 3 palavras')} style={{backgroundColor:'#c1c1c1', width:'100%'}}>Enviar Mensagem</button>
                                }
                              </div>
                            </div>
                           
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

