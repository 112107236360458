import '../../App.css';
//imagens
//icons
import IconBack from '../../acets/img_icon/back.svg'
import IconCar from '../../acets/img_icon/carrinho.svg'
import imgBkgCombo from'../../acets/img_bkg/combo_food.svg';
//import ImgSlapScreen2 from '../../acets/img_bkg/gififoood.gif'
import ImgSlapScreen from '../../acets/img_bkg/gifc360.gif'
//components
import Cardapio from '../partils/Cardapio';
import Menu from '../partils/Menu'
//import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
//import Nextselect from'../../acets/img_icon/nextselect.svg'
import Nextnoselect from'../../acets/img_icon/nextnoselect.svg'
import IconSearch from '../../acets/img_icon/search.svg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import TenancyColor from '../midllers/TenancyColor';
import SucessAlert from '../partils/SucessAlert'

/*
STATUS DE PEDIDOS E PRODUTOS
PEDIDO
<option value={"1"}> Aberto</option>
<option value={"2"}> Em produção</option>
<option value={"3"}> Finalizado</option>
<option value={"4"}> Cancelado</option>
PRODUTO
3 Finalizado
5 DESTAQUE
6 desativado
*/
export default function Painel(){
  const tenancylocal = localStorage.getItem('ClienteTenancy')
  const capaimglocal = localStorage.getItem('localcapaimg')
  const localpreparo = localStorage.getItem('localpreparo')
  axios.defaults.headers.common['id_tenancy'] = tenancylocal;
  const [keyCategoria,setKeyCategoria] = useState(false)
  const [data,setData] = useState([])
  const [datapromo,setDatapromo] = useState([])
  const [opcionaisData,setOpcionaisData]=useState([])
  const [dataDetails, setDataDetails] = useState([])

  const [selectedReduce, setSelectedReduce] = useState(0);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [pesquisa,setPesquisa] = useState()
  const [countSt, setCountSt] = useState(1)

  const [showSplashscreen, setShowSplashscreen] = useState(false);
  
  const [showDetaisDestaque, setShowDetaisDestaque] = useState(false);
  const [finaldeaisdestaque, setFinalDeaisDestaque] = useState(null);
  const[openSucess,setOpenSucess]=useState(false)

  useEffect(() => {
    rec();
    //Aparecer a tela de Splash Sreen com 2,5s de dalay
    const splashscreenShown = localStorage.getItem('splashscreenShown');
    if (!splashscreenShown) {
      setShowSplashscreen(true);
      const timer = setTimeout(() => {
        setShowSplashscreen(false);
      }, 2600);
      localStorage.setItem('splashscreenShown', true);
    }
  }, []);
  
  function rec(){
    axios.get('api/produtocategoria').then(resp=>{
      setData(resp.data)
    })
    axios.get('api/produto').then(resp=>{
      setDatapromo(resp.data)
      //setSplashscreen(false)
    })

  }

  const addToCar = function (idprod,qnt, id, preco,selectedCheckboxes) {
    const dataObj = {'id_produto':idprod, 'id_produto_state': Math.floor(Math.random() * 256), 'quantidade': qnt, 'nome': id, 'preco': preco,'opcionais':JSON.stringify(selectedCheckboxes)};
    if (localStorage.getItem('items') === null) {// Adicionando um array com um objeto no localstorage
      localStorage.setItem('items', JSON.stringify([dataObj]));
    } else { // Copiando o array existente no localstorage e adicionando o novo objeto ao final.
      localStorage.setItem(
        'items',// O JSON.parse transforma a string em JSON novamente, o inverso do JSON.strigify
        JSON.stringify([
          ...JSON.parse(localStorage.getItem('items')),
          dataObj
        ])
      );
    }
    setShowDetaisDestaque(false)
    setOpenSucess(true)
    setSelectedCheckboxes({})
    setTimeout(() => {
      setOpenSucess(false)
    }, "1000")
  }


 //console.log(datapromo)

  const ItensCategoria = data.map(strings=>
    <div key={strings.id_produto_categoria} className="ml5">
      <div className='we120px h5  m10 sombra-small row around clicado pp cont-circ7 b2' onClick={()=>setKeyCategoria(strings.id_produto_categoria)}>
        <img src={strings.imagem.patch} alt={strings.nome_categoria} className="w20"/> 
        <label className="pp cen ">{strings.nome_categoria}</label>
      </div>
    </div>
    )
    const divStyle = {
      backgroundImage: 'url('+(capaimglocal?capaimglocal:imgBkgCombo)+')',
      backgroundPosition:'center',
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat'
    };

    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
      const { name, value } = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
      setPesquisa(value); //SETANDO O VALUE EM NAME
      
    }
    

    const serverUrl = axios.defaults.baseURL
    const today = new Date();
const dayOfWeek = today.getDay();

const ItensCombo = datapromo.map(strings => {
  if (strings.status === 5 && (!strings.indisponivel_semana || !strings.indisponivel_semana.includes(dayOfWeek))) {
    return (
      <div className='we190px h15  ml5 cont-circ15 b2 sombra-small' key={strings.id_produto} onClick={() => handleClick(strings.id_produto)}>
        <div style={{
          backgroundImage: `url(${serverUrl}${strings.foto})`,
          backgroundColor: 'rgba(68, 68, 68, 0.54)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }} className='h10 cont-circ15 column end'>
          <div className='w80 ma10b0'>
            <label className='b2 cont-circ7 p5 fs06em'>
              {localpreparo === null || localpreparo === "" || localpreparo === 'null' ? "25-35min" : localpreparo}
            </label>
          </div>
        </div>
        <div className='h5 column pcb7'>
          <label className='w80 fb montserrat'>{strings.nome.substring(80, 0)}</label>
          <span className='w80 fs08em ma5'>{strings.ingredientes.substring(18, 0)} mais...</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
});
    /*
    const ItensCombo = datapromo.map(strings=>
      strings.status===5&&
      <div className='we190px h15  ml5 cont-circ15 b2 sombra-small'key={strings.id_produto} onClick={()=>handleClick(strings.id_produto)}>
        <div  style={
          {
          backgroundImage: `url(${serverUrl}${strings.foto})`,
          backgroundColor:'rgba(68, 68, 68, 0.54)',
          backgroundPosition:'center',
          backgroundSize:'cover',
          backgroundRepeat:'no-repeat'
          }} className='h10 cont-circ15 column end'>
        <div className='w80 ma10b0'>
        <label className='b2 cont-circ7 p5 fs06em'>
        {localpreparo === null || localpreparo === "" || localpreparo === 'null'? "25-35min": localpreparo}
        </label>
        </div>
        </div>
        <div className='h5 column pcb7'>
          <label className='w80 fb montserrat'>{strings.nome.substring(80,0)}</label>
          <span className='w80 fs08em ma5'>{strings.ingredientes.substring(18,0)} mais...</span>
        </div>
      </div>
      )*/
      const handleCheckboxChange = (name, checked, preco_opcional) => {
        setSelectedCheckboxes((prevSelectedCheckboxes) => {
          const updatedCheckboxes = {
            ...prevSelectedCheckboxes,
            [name]: {
              checked,
              preco_opcional,
            },
          };
          const selectedCount = Object.values(updatedCheckboxes).reduce(
            (count, item) => count + (item.checked ? 1 : 0),
            0
          );
          if (dataDetails.limite !== 0 && selectedCount > dataDetails.limite) {
            return prevSelectedCheckboxes; // Limite atingido, não atualize o estado
          }
          setSelectedReduce(selectedCount);
          return updatedCheckboxes;
        });
      };

      const datafinalopcionais = opcionaisData.map((stringopcionais) => {
        const limite = dataDetails.limite || 0; // Define o limite como 0 se for undefined
        const disabled = limite !== 0 && limite === selectedReduce && selectedReduce >= limite;
      
        return (
          <div className='row between ma20b0' key={stringopcionais.id_produto_opcoes}>
            <input
              type="checkbox"
              id={stringopcionais.nome + stringopcionais.id_produto_opcoes}
              name={stringopcionais.nome}
              checked={selectedCheckboxes[stringopcionais.nome]?.checked || false}
              onChange={(e) =>
                handleCheckboxChange(
                  stringopcionais.nome,
                  e.target.checked,
                  stringopcionais.preco_final
                )
              }
              disabled={disabled}
            />
      
            <label className='w70 fs1p3em' htmlFor={stringopcionais.nome + stringopcionais.id_produto_opcoes}>
              {stringopcionais.nome.charAt(0).toUpperCase() + stringopcionais.nome.slice(1)} - 
            </label>
            <label className='w20'>R$:{stringopcionais.preco_final}</label>
          </div>
        );
      });
    
      let totalPreco = 0;
      for (const item in selectedCheckboxes) {
        if (selectedCheckboxes[item].checked) {
          totalPreco += selectedCheckboxes[item].preco_opcional;
        }
      }
    

    function handleClick(id) {
      setShowDetaisDestaque(true);
      axios.get('/api/produto/' + id).then(resp => {
        setDataDetails(resp.data[0])
        axios.get('/api/produto_opcoes/'+id).then(resp=>{
          setOpcionaisData(resp.data)
          setSelectedCheckboxes({});
          setSelectedReduce(0);
        })
      })
    }
      //const dadosPromocionais = JSON.parse(datapromo);
      
     


    
    
  
  return(
    <div className="w100  h100 column center ">
      {showSplashscreen &&
      <div className='w100 h100 column center b2 z10 posifixedinicio desktop-none'>
        <div className='w100 h100 z10 posifixedinicio column center fb'>
          <h5>{tenancylocal.charAt(0).toUpperCase() + tenancylocal.slice(1)}</h5>
          <label>Delivery</label>
        </div>
        <img src={ImgSlapScreen} className='w100 h100 posifixedcenter'/>
      </div>
      }
      {
        showDetaisDestaque&&
        <div className='fundo-opaco w100 h100 posifixedinicio column center z10' key={dataDetails.id_produto}>
          <div className='b2 w30 cont-circ8 column center cell-tamanho'>
          <div className='b2 w5 cell-tamanho10 abstop10left10 column center h5 ma10b0 pp cont-circ15 cell-left5 borda1' onClick={() => setShowDetaisDestaque(false)& setCountSt(1)}>
            <img src={IconBack}></img>
          </div>

          {dataDetails.foto?
          <div className='w100 b1 column center cont-circ-root-bottom' style={{backgroundImage: `url(${serverUrl}${dataDetails.foto})`,backgroundPosition:'center',backgroundSize:'cover'}}>
            <label className='borda1 cont-circ15 p7 abstop10le b2 cell-left5'>
              {localpreparo === null || localpreparo === "" || localpreparo === 'null'? "25-35min": localpreparo}
            </label>
            <img src={`${serverUrl}${dataDetails.foto}`} alt="produto" className="w100 he40 cont-circ-root-bottom "/>
          </div>:
          <div className='w100 h40 b15 column center cont-circ-root-bottom'>
            <h5 className='fs2em w90 ma10b0 fb montserrat c1'>{tenancylocal}</h5>
          </div>
          }
            <div className='h100 w100 column between '>
              <div className='he50 row center overflowY'>
                <h5 className='fs1p3em w90 ma10b0 fb montserrat'>{dataDetails.nome}</h5>
                <div className='c4 fs1p1em w90 h50 overflowY'>
                    <pre className='c4 fs1p1em w90 overflowY'>{dataDetails.ingredientes}</pre>
                    
                    {datafinalopcionais}
                    
                </div>
                <label className='h15'>{tenancylocal}</label>
              </div>
              <div className='sombra w30 cont-circ-root column center posifixedfim b2 cell-tamanho'>
                <div className='ma10b0 w90'>
                  <div className='row between w100'>
                  <h6 className='fb montserrat'>R$: {dataDetails.preco_final? (dataDetails.preco_final* countSt+totalPreco).toFixed(2):<>Carregando...</>} </h6>
                    <div onClick={() => setShowDetaisDestaque(false) & setCountSt(1) & setDataDetails([])} className='borda1 pp cont-circ15 b2 h6 row center w24'>
                      <img src={IconBack} className='w20'></img>
                      <label className='pp'>Voltar</label>
                    </div>
                    <div className='borda1 cont-circ15 b2 row'>
                      <div onClick={() => setCountSt(countSt + 1)} className='cont-circ50pc b2 c1 p14 pp'>+</div>
                      <label className='mac0l8'>{countSt}</label>
                      <div onClick={() => setCountSt(countSt > 1 && countSt - 1)} className='cont-circ50pc b2 c1 p14 pp'>-</div>
                    </div>
                  </div>
                </div>
                <div className='h5 w100 column between ma5 posiabsrelativebotton0'>
                  
                {countSt && dataDetails.preco_final &&
                  <button onClick={() => addToCar(dataDetails.id_produto, countSt, dataDetails.nome, dataDetails.preco_final, selectedCheckboxes)} className='w90 h5 cont-circ15 row center' style={{backgroundColor: TenancyColor().backgroundColor}}>
                    Adicionar ao carrinho
                    <img src={IconCar} className="w6 cell-tamanho10" alt="Ícone do carrinho"></img>
                  </button>
                }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {openSucess&&
        <SucessAlert/>
      }
    <Menu/>
    <header className='w100 h10 h5 column center'>
    <div className='w90 h10 column end'>
      <div className='w100 row start'>
        <h4 className='montserrat ma20b0'>{tenancylocal.charAt(0).toUpperCase() + tenancylocal.slice(1)} ​</h4>
        <span>-Delivery</span>
      </div>
     
      <div className='w100 row between borda1 cont-circ b2' >
        <div className='w85 ml5'>
          <input className='inputsearchfront b2' placeholder='search' name='search' onChange={onChange}></input>
        </div>
        <div  className='w10 h5 column center'><img src={IconSearch} alt="pesquisa"/></div>
      </div>
    </div>
    
    </header>
    
  
    <section className='w100 column center'>
    <label className='fs1p3em w90 ma10b0'>Destaque</label>
      <div className='w90 h10'>
        <div className='overflow w100 h15'>
          <div className='wmin1200 h15 row start ma5 '> 
             {ItensCombo}
          </div>
        </div>
      </div>
    </section>

    <section className='w100 h10 column center'>
      <div className='w90'>
        <div className='w100 row start'>
        <label className='fs1p3em ma10b0'>Categoria </label><label className='fb'> - Todas</label>
        </div>
        <div className='overflow w100 h10' >
          <div className='we2200 row start' >
            {ItensCategoria}
          </div>
        </div>
      </div>
    </section>

    <div className='w100 h20' id="cardapio-categoria">
      <Cardapio KeyCategoria={keyCategoria} Pesquisa={pesquisa}/>
    </div>  
    
    
   
    <footer className="w100 h7 b3 column center">
    <Link to="/" className="fb c2">c360</Link>
    </footer>
  </div>
  )
 
  //style={divStyle}
}