import axios from "axios";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

function ChartProdutosMaisVendidos() {
  const [data,setData]=useState([])
    const [salesData, setSalesData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    
    // UseEffect para atualizar o estado com os dados da API
    useEffect(() => {
      axios
      .get("/api/pivo_pedido_cliente/").then((resp) =>{
      setData(resp.data)
      setSalesData(resp.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    //console.log(data)
    // Função para atualizar o estado com o mês selecionado pelo usuário
    const handleMonthSelect = (event) => {
      setSelectedMonth(event.target.value);
    };
    
    // Código para criar o gráfico de pizza usando o Google Charts
    const chartData = [['Produto', 'Quantidade']];
    
    // Filtra os dados de vendas pelo mês selecionado
    const filteredSalesData = selectedMonth
      ? salesData.filter((sale) => sale.createdAt.startsWith(selectedMonth))
      : salesData;
    
    // Cria um objeto com a quantidade total de vendas para cada produto
    const salesByProduct = filteredSalesData.reduce((acc, curr) => {
      const productName = curr.produto.nome;
      acc[productName] = acc[productName] ? acc[productName] + curr.quantidade : curr.quantidade;
      return acc;
    }, {});
    
    // Adiciona os dados de vendas para cada produto no array de dados do gráfico
    Object.keys(salesByProduct).forEach((productName) => {
      chartData.push([productName, salesByProduct[productName]]);
    });
  
    return (
      <div className="w100 column center">
        <div className="w90">
          <h5>Produtos mais vendido no periodo</h5>
          <label htmlFor="month-select" >Filtrar por mês:</label>
          {/* Select para a paginação por mês */}
          <select onChange={handleMonthSelect}>
            <option value="">Todos os meses</option>
            <option value="2023-01">Janeiro 2023</option>
            <option value="2023-02">Fevereiro 2023</option>
            <option value="2023-03">Março 2023</option>
            <option value="2023-04">Abril 2023</option>
            <option value="2023-05">Maio 2023</option>
            <option value="2023-06">Junho 2023</option>
            <option value="2023-07">Julho 2023</option>
          </select>
        </div>
        {/* Gráfico de pizza usando o Google Charts */}
        <Chart
          chartType="PieChart"
          data={chartData}
          options={{
            title: 'Vendas por Produto',
            is3D: false,
          }}
          width="100%"
          height="400px"
        />
      </div>
    );
};



export default ChartProdutosMaisVendidos;
