import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
//import OneSignal from 'react-onesignal';

import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import { Link } from 'react-router-dom'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'
export default function AdminDadosEmpresa(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Empresa',
        mainstring:'empresa',
        url:'/api/empresa/',
        url2:'',
        search:'/api/empresa/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS

     useEffect(()=>{
        window.OneSignal = window.OneSignal || [];
        window.OneSignal.push(function() {
            window.OneSignal.init({
                appId: "bbcd891c-502e-49d6-8a57-e3c4b5e13b5f",
                safari_web_id: "web.onesignal.auto.44e6ca0d-bbca-461f-908e-69d9a6be2a2a",
                notifyButton: {
                enable: true,
                },
                allowLocalhostAsSecureOrigin: true,
            });
        });
       
       /* document.addEventListener('DOMContentLoaded', function() {
            OneSignal.init({
              appId: 'bbcd891c-502e-49d6-8a57-e3c4b5e13b5f',
            });
          });
        
        OneSignal.init({
            appId: 'bbcd891c-502e-49d6-8a57-e3c4b5e13b5f',
        });*/
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        cnpj_cpf: '',	status: '',	senha: '',	email: '',	razao: '',	nome_proprietario: '',	estado: '',cidade: '',numero: '',rua: '',id_tenancy:'',whatsapp:'', link01:'',link02:'',link03:'',link04:'',link05:'',abre:'',fecha:'',obs_agenda:'',segmento:'',capaimg:'',token:'nada de token',pix:'0',bairro:'',taxa_frete_proximo:'',taxa_frete_distante:'',pix_estatico:'',preparo:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const [showlinks, setShowlinks] = useState(false)
    //FUNÇÕES=====================================
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get('/api/empresa/tenancy/one/'+tenancylocal).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                    setData([resp.data])
                }
        })
        
        
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
            axios[values.id_empresa?'put':'post'](config.url,values).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
    }
    const editar = function(id){//EDITA VER CARREGA OS DADOS DO ITEM A SER EDITADO
        setOpenNew(true)
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data)
            })
    }
    //console.log(data)
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    //console.log(data)
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
       
    }
    //COMPONENTES DE LOOP==============================
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha celular'  key={namesString.id_empresa}> 
                <div className='w100 b26 cell-tamanho'>
                    <label className='w100 c4 fb'>Status:</label>
                    {namesString.status===1?
                    <div className='row'>
                        <img src={IconChecked}/>
                        <label>Aberto</label>
                    </div>:
                    <div className='row'>
                        <img src={IconNoChecked}/>
                        <label>Fechado</label>
                    </div>
                    }
                </div>
                <div className='row between w100 h7'>
                    <div className='w30 column b26 ma10b0'>
                        <label className='w100 c4 fb'>Razão:</label>
                        <div className='w100'> {namesString.razao}</div>
                    </div>
                    <div className='w30 column b26 ma10b0'>
                        <label className='w100 c4 fb'>cnpj cpf:</label>
                        <div className='w100'> {namesString.cnpj_cpf}</div>
                    </div>
                    <div className='w30 column b26 cell-tamanho ma10b0'>
                        <label className='w100 c4 fb'>Email:</label>
                        <div className='w100'> {namesString.email}</div>
                    </div>
                </div>
                

                <div className='w100 row between h7'>
                    <div className='w49 column b26 cell-tamanho ma10b0'>
                        <label className='w100 c4 fb '>Link: (copie e envie para os seus clientes)</label>
                        <div className='w100'> https://cardapio360.com.br/loja/{namesString.id_tenancy}</div>
                    </div>
                    <div className='w49 column b26 ma10b0'>
                        <label className='w100 c4 fb'>WhatsApp:</label>
                        <div className='w100'> {namesString.whatsapp}</div>
                    </div>
                </div>
                
                <div className='w100 row between h7'>
                    <div className='w49 column b26'>
                        <label className='w100 c4 fb'>Proprietario:</label>
                        <div className='w100'> {namesString.nome_proprietario}</div>
                    </div>
                    <div className='w49 column b26'>
                        <label className='w100 c4 fb'>Rua:</label>
                        <div className='w100'> {namesString.rua}</div>
                    </div>
                </div>

                <div className='row between w100 h7'>
                    <div className='w30 column b26'>
                        <label className='w100 c4 fb'>Estado:</label>
                        <div className='w100'> {namesString.estado}</div>
                    </div>
                    <div className='w30 column b26'>
                        <label className='w100 c4 fb'>Cidade:</label>
                        <div className='w100'> {namesString.cidade}</div>
                    </div>
                    <div className='w30 column b26'>
                        <label className='w100 c4 fb'>Numero:</label>
                        <div className='w100'> {namesString.numero}</div>
                    </div>
                </div>
                
                <div className='w100 h7 column center c4 b26'> {namesString.createdAt}</div>
                <div className="w100 h7 row between pp">
                    <button className='b3 c1' onClick={()=>alert("Para solicitar cancelamento envie um email para: contato.cardapio360@gmail.com e solicite cancelamento")}>Solicitar cancelamento</button>
                    <button className='b3 c1' onClick={()=>editar(namesString.id_empresa)}> Editar</button>
                </div>
            </div>
    )
    return(
        <>
        {openNew&&           
            <div className='w100 h150 posiabstop0  column center z4'>
                <div className='w100 h150 posiabstop0 pcb50 fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90 '>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_empresa?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div>
                                <label>Razao:</label>
                                <input type="text"  name="razao"  onChange={onChange} value={values.razao} autoComplete='off'></input>
                            </div>
                            <div className='w100 row between'>
                                <div className='w49 column'>
                                    <label className='w100'>Patch/Link:</label>
                                    {!values.id_empresa?
                                    <input type="text"  name="id_tenancy"  onChange={onChange} value={values.id_tenancy} autoComplete='off'></input>:<label className='w100 b8 h4'>{values.id_tenancy}</label>
                                    }
                                </div>
                                <div className='w49'> 
                                    <label>WhatsApp:</label>
                                    <input type="number"  name="whatsapp"  onChange={onChange} value={values.whatsapp} autoComplete='off'></input>
                                </div>
                            </div>

                            <div className='w100 row between'>
                                <div className='w49'>
                                    <label>Estado:</label>
                                    <select type="text"  name="estado"  onChange={onChange} >
                                        <option value={"AC"}> Acre</option>
                                        <option value={"AL"}> Alagoas</option>
                                        <option value={"AP"}> Amapá</option>
                                        <option value={"AM"}> Amazonas</option>
                                        <option value={"BA"}> Bahia</option>
                                        <option value={"CE"}> Ceara</option>
                                        <option value={"DF"}> Distrito Federal</option>
                                        <option value={"ES"}> Espírito Santo</option>
                                        <option value={"GO"}> Goiás</option>
                                        <option value={"MA"}> Maranhão</option>
                                        <option value={"MT"}> Mato Grosso</option>
                                        <option value={"MS"}> Mato Grosso do Sul</option>
                                        <option value={"MG"}> Minas Gerais</option>
                                        <option value={"PA"}> Pará</option>
                                        <option value={"PB"}> Paraíba</option>
                                        <option value={"PR"}> Paraná</option>
                                        <option value={"PE"}> Pernambuco</option>
                                        <option value={"RJ"}> Rio de Janeiro</option>
                                        <option value={"RN"}> Rio Grande do Norte</option>
                                        <option value={"RS"}> Rio Grande do Sul</option>
                                        <option value={"RO"}> Rondônia</option>
                                        <option value={"RR"}> Roraima</option>
                                        <option value={"SC"}> Santa Catarina</option>
                                        <option value={"SP"}> São Paulo</option>
                                        <option value={"SE"}> Sergipe</option>
                                        <option value={"TO"}> Tocantins</option>
                                    
                                    </select>
                                </div>
                                <div className='w49'>
                                        <label>Cidade:</label>
                                        <input type="text"  name="cidade"  onChange={onChange} value={values.cidade}></input>
                                </div>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Bairro:</label>
                                    <input type="text"  name="bairro"  onChange={onChange} value={values.bairro}></input>
                                </div>
                                <div className='w30'>
                                    <label>Rua:</label>
                                    <input type="text"  name="rua"  onChange={onChange} value={values.rua}></input>
                                </div>
                                <div className='w30'>
                                    <label>Numero:</label>
                                    <input type="text"  name="numero"  onChange={onChange} value={values.numero}></input>
                                </div>
                            </div>
                            
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Cnpj ou cpf:</label>
                                    <input type="text"  name="cnpj_cpf"  onChange={onChange} value={values.cnpj_cpf}></input>
                                </div>
                                <div className='w30'>
                                    <label>email:</label>
                                    <input type="text"  name="email"  onChange={onChange} value={values.email}></input>
                                </div>
                                <div className='w30'>
                                    <label>senha:</label>
                                    <input type="text"  name="senha"  onChange={onChange} value={values.senha}></input>
                                </div>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Nome proprietario:</label>
                                    <input type="text"  name="nome_proprietario"  onChange={onChange} value={values.nome_proprietario}></input>
                                </div>
                                <div className='w30'>
                                    <label>Segmento:</label>
                                    <select type="text"  name="segmento"  onChange={onChange} >
                                        <option value="1">Selecione</option>
                                        <option value="1">Hamburgueria</option>
                                        <option value="2">Pastelaria</option>
                                        <option value="3">Fast food geral</option>
                                        <option value="4">Padaria</option>
                                        <option value="5">Mercadinho</option>
                                        <option value="6">Sorveteria</option>
                                        <option value="7">Doceria/confeitaria</option>
                                        <option value="8">Bolos</option>
                                        <option value="9">Equipamentos</option>
                                        <option value="10">Oficina mecanica</option>
                                        <option value="11">Assistencia Técnica</option>
                                    </select>
                                </div>
                                <div className='w30'>
                                    <label>Cor do tema:</label>
                                    <input type='color' className='w100 ma10b0' name='color1' onChange={onChange} value={values.color1}/>
                                </div>
                            </div>
                            <div>
                                <label>Chave pix Estatico</label>
                                <strong className='fs08em'> - Sem verificação automatica de pagamento</strong>
                                <input type="text"  name="pix_estatico"  onChange={onChange} value={values.pix_estatico}></input>
                            </div>
                            <div>
                                <label>Chave pix - Banco gerencianet/ÉFI: </label>
                                <Link to={{ pathname: "https://sejaefi.com.br" }} target="_blank" className='fb' >Criar Conta</Link>
                                <input type="text"  name="pix"  onChange={onChange} value={values.pix}></input>
                            </div>
                            {values.id_empresa&&
                                <div>
                                    <div>
                                        <label>Imagem de capa Bio(url)</label>
                                        <input type="text"  name="capaimg"  onChange={onChange} value={values.capaimg}></input>
                                    </div>
                                    <div className='row between w100'>
                                        <div className='w30'>
                                            <label>Abre ás:</label>
                                            <input type="time"  name="abre"  onChange={onChange} value={values.abre}></input>
                                        </div>
                                        <div className='w30'>
                                            <label>Fecha ás:</label>
                                            <input type="time"  name="fecha"  onChange={onChange} value={values.fecha}></input>
                                        </div>
                                        <div className='w30'>
                                            <label>Preparo:(10-30m)</label>
                                            <input type="text"  name="preparo"  onChange={onChange} value={values.preparo}></input>
                                        </div>
                                    </div>
                                    <div className='w100'>
                                            <label>Obs de agenda:</label>
                                            <input type="text"  name="obs_agenda"  onChange={onChange} value={values.obs_agenda}></input>
                                        </div>
                                    <div className='w100 b15 ma5 pcb7 pp' onClick={()=>setShowlinks(true)}>
                                        Links da bio, Instagram, ifood, whatsapp v
                                    </div>
                                    {showlinks&&<>
                                     <div>
                                        <label>Link Ifood: ex: https://www.ifood.com.br</label>
                                        <input type="text"  name="link01"  onChange={onChange} value={values.link01}></input>
                                    </div>
                                    <div>
                                        <label>Link Instagram:</label>
                                        <input type="text"  name="link02"  onChange={onChange} value={values.link02}></input>
                                    </div>
                                    <div>
                                        <label>Link WhatsApp: Ex:77 9999 9999</label>
                                        <input type="text"  name="link03"  onChange={onChange} value={values.link03}></input>
                                    </div>
                                    <div>
                                        <label>Facebook:</label>
                                        <input type="text"  name="link04"  onChange={onChange} value={values.link04}></input>
                                    </div>
                                    <div>
                                        <label>Link:</label>
                                        <input type="text"  name="link05"  onChange={onChange} value={values.link05}></input>
                                    </div>
                                    </>}
                                    <div className='w100 b15 h10 row between'>
                                        <div className='w100'>
                                            <label>O seu delivery esta: </label>
                                             {values.status===1?<label className="fb c10">Aberto!</label>:<label className="fb c9">Fechado!</label>}
                                        </div>
                                        <div className='w45 row around'>
                                            <input type='radio' value={1} name='status' onChange={onChange}></input>
                                            <label>Abrir Delivery</label>
                                        </div>
                                        <div className='w45 row around'>
                                            <input type='radio' value={2} name='status' onChange={onChange}></input>
                                            <label>Fechar Delivery</label>
                                        </div>
                                    </div>
                                </div>}
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_empresa ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                            
                        </form>
                        <div className='w100 row between'>
                            {values.id_empresa?<button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_empresa)}>Excluir</button>:<label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 ">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h90 column center b15 b30'>
           <AdminHeader props={config.pageName}/>
           
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'> {config.pageName}</h5>
                        {tenancylocal==='starburguer'&&
                        <div className='row between w90'>
                            <div className='h4 b10 c3 cont-circ8 row center pp cell-tamanho-metade' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}> Add Empresa</div>
                            <Link to="/adminlead" className='h4 b10 c3 cont-circ8 row center cell-tamanho-metade' style={{backgroundColor: TenancyColor().backgroundColor}}>Leads</Link>
                        </div>
                        }
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
                            
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

