import React, { useState } from "react";
import { Chart } from "react-google-charts";

function ChartPedidosMes({ pedidos }) {

  const [selectedMonth, setSelectedMonth] = useState("all");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filterPedidosByMonth = (pedidos, selectedMonth) => {
    if (selectedMonth === "all") {
      return pedidos;
    }

    const month = parseInt(selectedMonth);
    return pedidos.filter((pedido) => {
      const pedidoMonth = new Date(pedido.createdAt).getMonth() + 1;
      return pedidoMonth === month;
    });
  };

  const pedidosByDay = filterPedidosByMonth(pedidos, selectedMonth).reduce(
    (acc, pedido) => {
      const pedidoDate = new Date(pedido.createdAt).toLocaleDateString();
      if (!acc[pedidoDate]) {
        acc[pedidoDate] = 1;
      } else {
        acc[pedidoDate] += 1;
      }
      return acc;
    },
    {}
  );

  const chartData = [["Dia", "Pedidos"]];

  // Percorre as chaves do objeto em ordem reversa e adiciona os valores ao gráfico
  const pedidosByDayKeys = Object.keys(pedidosByDay).reverse();
  for (let i = 0; i < pedidosByDayKeys.length; i++) {
    const dia = pedidosByDayKeys[i];
    const quantidadePedidos = pedidosByDay[dia];
    chartData.push([dia, quantidadePedidos]);
  }

  return (
    <div className="w100 column center">
      <div className="w90">
        <h5>Pedidos no periodo</h5>
        <label htmlFor="month-select" >Filtrar por mês:</label>
        <select id="month-select" value={selectedMonth} onChange={handleMonthChange}>
          <option value="all">Todos</option>
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </select>
      </div>

      <Chart
        chartType="LineChart"
        data={chartData}
        width="100%"
        height="400px"
        legendToggle
      />
    </div>
  );
}

export default ChartPedidosMes;
