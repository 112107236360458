import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function MenuPainelAdmin(){
  const tenancylocal = localStorage.getItem('ClienteTenancy')
  axios.defaults.headers.common['id_tenancy'] = tenancylocal;
  const [openmenu,setOpenMenu] = useState(false)
  useEffect(()=>{
    if ((window.screen.availHeight < 1234) &&(window.screen.availWidth < 1234)){
      //console.log("tela pequena")
    }
  else{
    setOpenMenu(true)
  //console.log("tela pequena")
  }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const deslogar =()=>{
      localStorage.removeItem('keyItemNameg');
      localStorage.removeItem('keyItemName');
      localStorage.removeItem('ItemUsers')
      window.location.href = "/";
    }
    
    return(
      <div className="w25 b2 h30 cont-circ3 sombra column cell-tamanho cell-margin10">
        <div className="w90 row start pp" onClick={()=>setOpenMenu(true)}>
          <h5 >Menu </h5>
          <label className="fb"> - {tenancylocal}</label>
        </div>
        {openmenu&&
          <div className="w100 column ">
            <label onClick={()=>setOpenMenu(false)} className="pp w90 h7 center column b27">Fechar Menu</label>
            <Link to="/painel" className="fb w90 ma10b0">Dashboard</Link>
            
          <Link to="/adminvendarapida" className="fb w90 ma10b0">Venda $$$</Link>
          <Link to="/adminpedido" className="fb w90 ma10b0">Pedidos</Link>
          <Link to="/adminpix" className="fb w90 ma10b0">Area PIX</Link>
          <Link to="/admingerarstory" className="fb w90 ma10b0">Gerar Story</Link>
          <Link to="/adminassistenteinteligente" className="fb w90 ma10b0 row">
            <label className="pp">Assistente Inteligente</label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp">Paused</div>
          </Link>
          
          <div className="w90 b26 column cont-circ7">
            <Link to="/adminimagen" className="fb w90 ma10b0">Icones<span className="fs08em text-smile-weight"> - Banco de icones</span></Link>
            <Link to="/admincategoriaproduto" className="fb w90 ma10b0">Categorias de Produtos</Link>
            <Link to="/adminproduto" className="fb w90 ma10b0">Produtos e Combos</Link>
            <Link to="/admincliente" className="fb w90 ma10b0">Clientes</Link>
            
          </div>
          <Link to="/admindadosempresa" className="fb w90 ma10b0">Dados Empresa</Link>
          <Link to="/adminentregador" className="fb w90 ma10b0 row">
            <label className="pp">Entregador - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
          <Link to="/adminfinanceirotenancy" className="fb w90 ma10b0 row">
            <label className="pp">Financeiro</label> 
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
          <Link to="/adminfrete" className=" w90 ma10b0 row">
            <label className="fb pp">Frete - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>

          <Link to="/adminfeature" className="fb w90 ma10b0">Feature<span className="fs08em text-smile-weight"> - Atualizações</span></Link>
          {
            // <Link to="/admindadosempresa" className="fb w90 ma10b0">Etiqueta e Patrimonio</Link>
            //<Link to="/adminprodutoestoque" className="fb w90 ma10b0">Estoque de produtos</Link>
          
          
            //<Link to="/adminlead" className="fb w90 ma10b0">Leads</Link>
          }
          <Link to="/" className="fb w90 ma10b0" onClick={deslogar}>Sair do app</Link>
        </div>
        }
        
        
      </div>
    )
}