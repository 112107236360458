import { useEffect, useState } from 'react'
import axios from 'axios'
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import IcoStatus from '../partils/IcoStatus'
import SucessAlert from'../../components/partils/SucessAlert'
import TenancyColor from '../midllers/TenancyColor'
import IcoEditOpc from'../../acets/img_icon/editopc.svg'
import IcoBin from '../../acets/img_icon/bin.svg'
import AdminHeader from '../partils/AdminHeader'
export default function AdminProduto(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Produto',
        mainstring:'nome',
        url:'/api/produto/',
        url2:'',
        search:'/api/produto/search/',
        urlOptions1:'/api/produtocategoria',
        
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '',ingredientes:'', quantidade: '',preco_final: '',status: '3',validade: '',preco_custo:'', id_produto_categoria: '',foto:'',limite:'',indisponivel_semana:''
    }
    const initialValuesOpcionais={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        id_produto:0,nome: '0',preco_final:'0',status: '1',preco_custo:'0'
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [valuesOpcionais, setValuesOpcionais]=useState(initialValuesOpcionais)
    const [data, setData] = useState([])
    const [dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    const [opcionaisData,setOpcionaisData]=useState([])
    const [opcionaisState, setOpcionaisState] = useState({});
    const [sucessmodal, setSucessmodal] = useState(false);
    const [showModalSemana, setShowModalSemana] = useState(false);
    const [arraySemana, setArraySemana] = useState([])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)
            //console.log(resp)
        })
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })
    }
    
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onChangeOpcionais(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValuesOpcionais({ ...valuesOpcionais, [name]:value }); //SETANDO O VALUE EM NAME
    }
    

  async function salvarOpcionais(id_produto) {
    try {
        const values = { ...valuesOpcionais, id_produto };
        const response = await axios.post('/api/produto_opcoes/', values);
        console.log(response);
        rec(); //setValuesOpcionais(initialValuesOpcionais)
        } catch (error) {
            console.log(error);
        }
    }

    function onSubmit(ev) {
        ev.preventDefault();
        const updatedValues = { ...values, indisponivel_semana: JSON.stringify(arraySemana) };
        axios[values.id_produto ? 'put' : 'post'](config.url, updatedValues).then(() => {
          setOpenNew(false);
          setValues(initialValues);
          rec();
          //console.log(resp.data)
        });
      }
      
    const editar = function(id){//
        //setValuesOpcionais({ ...valuesOpcionais, 'id_produto':namesString.id_produto})
        setOpenNew(true)
        setArraySemana([])
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data[0])
            setArraySemana(JSON.parse(respp.data[0].indisponivel_semana));
            })
        axios.get('/api/produto_opcoes/'+id).then(resp=>{
            setOpcionaisData(resp.data)
            })
       //console.log(opcionaisData)
    }
    const exclui = function(id){
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    const excluiOpcionais = function(id){
        axios.delete('/api/produto_opcoes/' + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    //console.log(valuesOpcionais)
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    const salvarFoto=(id_produto)=>{
        const formData = new FormData();
        const imagefile = document.querySelector("#foto");
        // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
        formData.append("foto", imagefile.files[0]);
        formData.append("id_produto",id_produto);
        const tlocal = localStorage.getItem('keyItemNameg')
        const tenancylocal = localStorage.getItem('ClienteTenancy')
        //const tlocal = localStorage.getItem('keyItemNameg')
           
        axios.put("/api/produtoImage/",formData, {
         headers: {
        'token':tlocal,
        'id_tenancy':tenancylocal,
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          'enctype': 'multipart/form-data'
         }
        })
        .then(resp =>{
            //console.log(resp.data)
            setOpenNew(false)
            rec();
        }
        );
     }
    //COMPONENTES DE LOOP==============================
    const serverUrl = axios.defaults.baseURL
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_produto}> 
                
                <div className='w100 row between'>
                  <div className='w5'>
                    <IcoStatus props={namesString.status}/>
                  </div>
                  <div className='w90 cell-tamanho80 fs1p1em text-smile-weight'>{namesString.nome}</div>
                  <b onClick={()=>editar(namesString.id_produto) } className='pp w5 row end'> <img src={IconEdit}></img></b>
                  
                </div>
                
                {namesString.foto?
                    <img src={`${serverUrl}${namesString.foto}`} className='w20 b12 h10'/>:
                    <div className='w20 b3 h10 column center fs08em'>Sem foto</div>
                }
                <div className='w80 column between '>
                   
                    <div className='row between w100'>
                      <div className='w30  cell-texto-p c4'>C-R$: {namesString.preco_custo}</div>
                      <div className='w30  cell-texto-p c4'>V-R$: {namesString.preco_final}</div>
                      <div className='w30  cell-texto-p c4'>L-R$: {String(namesString.preco_final-namesString.preco_custo).substring(0, 5)}</div>
                    </div>

                    <div className='w100 fs08em cell-tamanho cell-margin5 c4 ma10b0'>{String(namesString.ingredientes).substring(0, 72)}...</div>
                    
                    <div className="w100 h4 row between pp">
                        <div className='cell-texto-p c4'>Qnt: {namesString.quantidade}</div>
                        <div className='cell-texto-p c4 fim'> Val:{namesString.validade}</div>
                        <div className='cell-texto-p c4 fim'>{ namesString.id_produto}</div>
                    </div>
                </div>
            </div>
    )
    const optionInput = dataInput.map(repsValues=>
        <option key={repsValues.id_produto_categoria} value={repsValues.id_produto_categoria}>{repsValues.nome_categoria}</option>
    )
    function onChangeOpcionaisSave(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValuesOpcionais({ ...valuesOpcionais, [name]:value }); //SETANDO O VALUE EM NAME
    }
    let idCounter = 0;
    function onChangeOpcionais(ev) {
        const { name, value } = ev.target;
        const id = ev.target.dataset.opcionalId; // ID do opcional, definido no elemento de entrada
        setOpcionaisState((prevState) => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            [name]: value,
          },
        }));
    }
      function editarOpcionais(id) {
        const opcional = opcionaisState[id];
        if (opcional) {
          axios
            .put(`/api/produto_opcoes/${id}`, opcional)
            .then(resp => {
              setSucessmodal(true)
              //setOpcionaisData([])
              setTimeout(() => {
                setSucessmodal(false)
              }, 1000);
              //setOpenNew(false);
              //setValues(initialValues);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      function isSelected(day) {
        return arraySemana.includes(day);
      }
      const confirmExclusao = (id) => {
        const confirmar = window.confirm("Tem certeza que deseja excluir este item?");
        if (confirmar) {
          excluiOpcionais(id);
        }
      }
      const handleCheckboxChange = (diaSemana, checked) => {
        if (checked) {
          // Se o checkbox foi marcado, adicione o dia da semana ao arraySemana
          setArraySemana((prevArray) => {
            if (prevArray === null) {
              return [diaSemana];
            } else {
              return [...prevArray, diaSemana];
            }
          });
        } else {
          // Se o checkbox foi desmarcado, remova o dia da semana do arraySemana
          setArraySemana((prevArray) => {
            if (prevArray === null) {
              return null;
            } else {
              return prevArray.filter((dia) => dia !== diaSemana);
            }
          });
        }
      };
      const opcionaisfinal = opcionaisData.map((repsValues) => {
        const opcionalState = opcionaisState[repsValues.id_produto_opcoes] || {
          nome: repsValues.nome,
          preco_final: repsValues.preco_final,
        };
        idCounter++;
        return (
          <div key={idCounter} className="row around">
            {sucessmodal&&
                <SucessAlert/>
            }
            <div className="w65">
              <input
                type="text"
                name="nome"
                placeholder={repsValues.nome}
                value={opcionalState.nome}
                onChange={onChangeOpcionais}
                data-opcional-id={repsValues.id_produto_opcoes}
              />
            </div>
            <label>R$:</label>
            <div className="w10">
              <input
                type="number"
                name="preco_final"
                placeholder={repsValues.preco_final}
                value={opcionalState.preco_final}
                onChange={onChangeOpcionais}
                data-opcional-id={repsValues.id_produto_opcoes}
              />
            </div>
            <div className="w15 row between">
              <img src={IcoEditOpc} onClick={() => editarOpcionais(repsValues.id_produto_opcoes)} className='pp'/>
              
              <img src={IcoBin} onClick={() => confirmExclusao(repsValues.id_produto_opcoes)} className='pp'/>
            </div>
          </div>
        );
    });
    //console.log(arraySemana)


    return(
        <>
        {openNew&&           
            <div className='w100 h150 posiabstop0  column center z4 overflow' >
                <div className='w100 h150 posifixedinicio pcb50 fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.idproduto?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off' encType="multipart/form-data">
                            <div>
                                <label>Nome:</label>
                                <input type="text"  name="nome"  onChange={onChange} value={values.nome} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>Ingredientes:</label>
                                <textarea type="text"  name="ingredientes"  onChange={onChange} value={values.ingredientes}></textarea>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Quantidade:</label>
                                    <input type="number"  name="quantidade"  onChange={onChange} value={values.quantidade}></input>
                                </div>
                                <div className='w30'>
                                    <label>Custo:</label>
                                    <input type="number"  name="preco_custo"  onChange={onChange} value={values.preco_custo}></input>
                                </div>
                                <div className='w30'>
                                    <label>Preco final:</label>
                                    <input type="number"  name="preco_final"  onChange={onChange} value={values.preco_final}></input>
                                </div>
                            </div>
                            <div className='row between'>
                                <div className='w30'>
                                    <label>Status:</label>
                                    <select  name="status"  onChange={onChange} value={values.status}>
                                        <option value="3">Cad Normal</option>
                                        <option value="5">Destaque</option>
                                        <option value="6">Desativado</option>
                                        {/*<option value="1">Desativado</option>
                                        <option value="2">Indisponivel</option>
                                        <option value="5">Promoção</option>*/
                                        }
                                    </select>
                                </div>
                                <div className='w30'>
                                    <label>Validade:</label>
                                    <input type="date"  name="validade"  onChange={onChange} value={values.validade}></input>
                                </div>
                                <div className='w30'>
                                    <label>Limite de opcionais:</label>
                                    <input type="number"  name="limite"  onChange={onChange} value={values.limite}></input>
                                </div>
                            </div>
                            
                            <div>
                                <label>Categoria:</label>
                                <select name="id_produto_categoria" onChange={onChange} >
                                    <option>Selecione</option>
                                    {optionInput}
                                </select>
                            </div>
                            <div className='row between'>
                                <label className='w100'>Opcionais:</label>
                                <div className='w30'>
                                    <label>Nome:</label>
                                    <input type='text' placeholder='Ex: Queijo' name='nome' onChange={onChangeOpcionaisSave}></input>
                                </div>
                                <div className='w30'>
                                    <label>Preco:</label>
                                    <input type='number' name="preco_final" onChange={onChangeOpcionaisSave}></input>
                                </div> 
                                <div className='w30'>
                                    <label>Ação:</label>
                                  <button className='w100' onClick={() => salvarOpcionais(values.id_produto)}>Add</button>
                                </div>   
                                <div className='w100 b16'>
                                    {opcionaisfinal}
                                </div>   
                            </div>

                            {values.id_produto&&
                            <div className='w100 row between bordasendimage cont-circ8 ma10b0'>          
                                <div className="file-input-wrapper w50">
                                    <input className="file-input" type="file" id="foto" name="foto" accept=".jpeg, .jpg,.png"/>
                                    <img src="https://cdn-icons-png.flaticon.com/512/4738/4738618.png" alt="icon" />
                                    <span className="file-input-text">Escolha um arquivo</span>
                                </div>
                                <div className='w50 b16 b3 h7 row around pp' onClick={()=>salvarFoto(values.id_produto)}>
                                    <label >Salvar foto</label>
                                    <img src='https://cdn-icons-png.flaticon.com/512/9131/9131510.png' className='w15'/>
                                </div>
                                
                            </div>
                            }
                            <div className='w100 b16 pcb7'>
                                <span className="file-input-text pp" onClick={() => setShowModalSemana(true)}>
                                    Produto indisponível nos dias:
                                </span>
                                {showModalSemana && (
  <div className='w100 b16 pcb7 row between'>
    <div className='column center'>
      <label>Dom</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(0) : false}
        onChange={(e) => handleCheckboxChange(0, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Seg</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(1) : false}
        onChange={(e) => handleCheckboxChange(1, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Ter</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(2) : false}
        onChange={(e) => handleCheckboxChange(2, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Qua</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(3) : false}
        onChange={(e) => handleCheckboxChange(3, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Qui</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(4) : false}
        onChange={(e) => handleCheckboxChange(4, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Sex</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(5) : false}
        onChange={(e) => handleCheckboxChange(5, e.target.checked)}
      />
    </div>
    <div className='column center'>
      <label>Sáb</label>
      <input
        type='checkbox'
        checked={arraySemana ? arraySemana.includes(6) : false}
        onChange={(e) => handleCheckboxChange(6, e.target.checked)}
      />
    </div>
  </div>
)}
                                </div>
                            <div className='cell-margin5'>
                                <button type="submit" className='w100'>{values.id_produto ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            {values.id_produto?
                            <button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_produto)}>Excluir</button>:
                            <label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w20 h4 b10 c3 cont-circ8 row center pp cell-text-smile08' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
        
                            </div>
                            {final}
                            
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

