import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import IconLixo from '../../acets/img_icon/lixo.svg'

import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
//import IconEdit from '../../acets/img_icon/edit.svg'

import AdminItensPedido from '../../components/partils/AdminItensPedido'
import IcoStatus from '../partils/IcoStatus'

import IconSaberto from '../../acets/img_icon/ico_s_aberto.svg'
import IconScancel from '../../acets/img_icon/ico_s_cancel.svg'
import IconSfinalizado from '../../acets/img_icon/ico_s_finalizado.svg'
import IconSprod from '../../acets/img_icon/ico_s_prod.svg'
import IconSpromo from '../../acets/img_icon/ico_s_promo.svg'
import IconMiniWhatsApp from '../../acets/img_icon/icon_wpp_mini.svg'
import IconPrinter from '../../acets/img_icon/printer.svg'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminPedido(){
     const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Pedidos',
        mainstring:'nome',
        url:'/api/pedido/',
        url2:'',
        search:'/api/cliente/search/',
        urlOptions1:'/api/entregador',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     /*useEffect(() => {
        let intervalId; // Declaração da variável intervalId
        // Função que chama rec() a cada 50 segundos
        const recAndSetInterval = () => {
          rec();
          intervalId = setInterval(rec, 2000);
        };
        // Chama recAndSetInterval assim que a página for aberta
        recAndSetInterval();
        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
      }, []);
    */
      useEffect(() => {
        let intervalId;
      
        const recAndSetInterval = () => {
          rec();
          intervalId = setInterval(rec, 50000);
        };
      
        const handleVisibilityChange = () => {
          if (document.visibilityState === "visible") {
            // Se a página estiver visível, chama recAndSetInterval
            recAndSetInterval();
          } else {
            // Se a página estiver em background, limpa o intervalo
            clearInterval(intervalId);
          }
        };
      
        // Adiciona o listener para o evento visibilitychange
        document.addEventListener("visibilitychange", handleVisibilityChange);
      
        // Chama recAndSetInterval assim que a página for aberta
        recAndSetInterval();
      
        // Limpa o intervalo e remove o listener quando o componente é desmontado
        return () => {
          clearInterval(intervalId);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, []);
      
    //STATES======================================
    //id_pedido	status	forma_de_pagamento	troco	observacoes	cliente	createdAt	updatedAt
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        status: '',	forma_de_pagamento: '',	troco: '',bairro:'0',rua:'0',numero:'0',complemento:'0',observacoes:'null',	cliente: '',
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [openDetaisStatusPedido,setOpenDetaisStatusPedido]=useState()// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[dataInput,setDataInput]=useState([{"id_entregador":0}])
    //const [idparamsPedido, setIdparamsPedido] = useState()
    
    //const [options1,setOptions1] = useState([])
    //FUNÇÕES=====================================

    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(values.status?'/api/pedido/status/'+values.status:config.url).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
        })
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })
            
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onChangeSelect(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
        //console.log(value)
        axios.get(value?'/api/pedido/status/'+value:config.url).then(resp=>{
        setData(resp.data)})
    }
    

    function alterar(idpedido,status) {
      axios.put('/api/pedido/' + idpedido, { 'status': status }).then(resp => {
        rec();
      });
    }
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
       
    }
    
    function redirectToWhatsapp(phoneNumber, message) {
        let url = `https://wa.me/+55${phoneNumber}`;
        if (message) {
          url += `?text=${message}`;
        }
        window.open(url, '_blank');
      }
    const optionInput = dataInput.map(repsValues=>
        <div key={repsValues.id_entregador}className='w100 linha ma10b0 pp pcb10 row pp' onClick={() => redirectToWhatsapp(repsValues.telefone)}>
            <label className='w100 c1 pp'>{repsValues.telefone+" - "+repsValues.nome}</label>
        </div>
    )
    const handlePrint = (pedidoId) => {
        const printableContent = document.getElementById(`contentToPrint_${pedidoId}`);
        // Cria uma janela temporária para a impressão
        const printWindow = window.open("", "_blank", "width=620,height=520");
        // Escreve o conteúdo que deseja imprimir na janela temporária
        printWindow.document.write(printableContent.innerHTML);
        // Executa a impressão sem mostrar a caixa de diálogo de impressão
        printWindow.print();
        // Fecha a janela temporária após a impressão
        printWindow.close();
      };
        
    const filteredData = data.filter(namesString => namesString.forma_de_pagamento !== '0'||0); // FILTRA OS DESATIVADOS
    const final = filteredData.map(//MAPEIA TODO O FILTRO
        
    namesString =>{
        const date = new Date(namesString.createdAt);
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const formattedDate = date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: '2-digit' });

        return (
            <div className='w100 h4 column center ma5 sombra02 cont-circ7'  key={namesString.id_pedido} id={`contentToPrint_${namesString.id_pedido}`}> 
                <div className='w90 column center pcb20'>
                    <div className='w100 row between b27'>
                        <div className='w10'>
                            <IcoStatus props={namesString.status}/>
                        </div>
                        <div className='w60 fb c1 fs1p5em ma10b0'>{namesString.cliente.nome.charAt(0).toUpperCase() + namesString.cliente.nome.slice(1)}</div>
                        {
                        <div className="w30 h4 row end">
                           
                            <button onClick={()=>setOpenDetaisStatusPedido(namesString.id_pedido)||openDetaisStatusPedido===namesString.id_pedido&&setOpenDetaisStatusPedido(false) } className='b15 c1 borda1'>Status</button>
                            <button className='row center b15 c1 borda1 cell-none' onClick={() => handlePrint(namesString.id_pedido)}>
                                <img src={IconPrinter}  className='ml5'/>
                                <label>Print</label>
                            </button>
                            

                            {openDetaisStatusPedido===namesString.id_pedido&&
                            <div className='w100 h100 posifixedinicio fundo-opaco left0 column center' onClick={()=>setOpenDetaisStatusPedido(false)}>
                                <div className='w40 h40 b2 cell-tamanho90 cont-circ8 column center'>
                                    <div className='w90'>
                                
                                        <h5 className=''>Alterar Status</h5>
                                        <label className='w100'>Cliente:</label>
                                        <strong>{namesString.cliente.nome.charAt(0).toUpperCase() + namesString.cliente.nome.slice(1)}</strong>
                                        
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_pedido,1)}>
                                            <img src={IconSaberto} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Em Aberto</label>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_pedido,2)}>
                                            <img src={IconSprod} alt="fff" className='mac0l8'/>
                                            <label className='pp w80'>Em produção</label>
                                            <img src={IconMiniWhatsApp} alt="fff" onClick={
                                                ()=>redirectToWhatsapp(
                                                namesString.cliente.telefone,
                                                "O seu pedido já está em produção!")
                                            }/>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_pedido,3)}>
                                             <img src={IconSfinalizado} alt="fff" className='mac0l8'/>
                                            <label className='pp w80'>Finalizado</label>
                                            <img src={IconMiniWhatsApp} onClick={
                                                ()=>redirectToWhatsapp(
                                                namesString.cliente.telefone,
                                                "Obaaa, o seu pedido já está a caminho!")
                                            }/>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_pedido,4)}>
                                            <img src={IconScancel} alt="fff" className='mac0l8'/>
                                            <label className='pp w80'>Cancelado</label>
                                            <img src={IconMiniWhatsApp} onClick={
                                                ()=>redirectToWhatsapp(
                                                namesString.cliente.telefone,
                                                "Ops, pedido cancelado!")
                                            }/>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>exclui(namesString.id_pedido)}>
                                             <img src={IconScancel} alt="fff" className='mac0l8'/>
                                            <label className='pp w20'>Excluir</label>
                                            <label className='fs06em  w60'>Para excluir o pedido exclua todos os itens do pedido. Essa é uma ação permanente.</label>
                                        </div>  
                                        <label className='fs08em'> Duvidas sobre o pedido?</label>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={() => redirectToWhatsapp(namesString.cliente.telefone)}>
                                            <img src={IconMiniWhatsApp} className='mac0l8'/>
                                            <label className='c1 pp'>WhatsApp cliente - 
                                                <spam className='fs08em'> {namesString.cliente.telefone}</spam>
                                            </label>
                                        </div>
                                        <div className='he15 overflowY'>
                                            <div className='h50 b17'>
                                                <label className='fs08em'>Entregadores:</label>
                                                {!(dataInput[0] === {"id_entregador":0}) && optionInput}
                                            </div>
                                        </div>
                                        <div className='row between ma25b0'>
                                            <label>Sem mais opções</label>
                                            <button className='btn05' onClick={()=>setOpenDetaisStatusPedido(false)}>Cancelar</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                    
                    <div className='w100  row between b27 linha'>
                        <div className='column w33'>
                            <label className='fb fs08em w90'>{formattedTime}h</label>
                            <label className='fs08em w100'>{formattedDate}</label>
                        </div>
                        <div className='column w33'>
                            <label className='fb fs08em'>{namesString.forma_de_pagamento}</label>
                            <label className='fs06em'>F. DE PGTO.</label>
                        </div>
                        <div className='column w33'>
                            <label className=' fb fs08em'>{namesString.id_pedido}</label>
                            <label className='fs06em'>ID PEDIDO</label>
                        </div>
                    </div>
                    <div className='w100 b27 w100 column '>
                        <div className='w100 row between '>
                            {namesString.forma_de_pagamento==="DINHEIRO"&&
                                <label>Troco para R$:{namesString.troco}</label>
                            }
                        </div>
                    
                        <div className='w100 row between  linha'>
                            <label className='cell-tamanho'>
                                <span className='fs06em'>Bairro: </span>
                                {namesString.bairro}
                            </label>
                            <label className=''>
                                <span className='fs06em'>Rua: </span>
                                {namesString.rua}
                            </label>
                            <label>
                                <span className='fs06em'>Nº:</span>
                                {namesString.numero}
                            </label>
                        </div>
                        {namesString.complemento&&
                        <div className='w100 linha'>
                            <div className='w100 fs06em'>Complemento:</div>
                            <label>{namesString.complemento}</label>
                        </div>
                        }
                        {namesString.observacoes&&
                        <label className='cell-texto-p w100 linha'>Obs:{namesString.observacoes}</label>
                        }
                    
                    </div>
                
                    <div className='w100'>
                        <AdminItensPedido id={namesString.id_pedido}/>
                    </div>
                </div>
            </div>);
})

   


    return(//id_pedido	status	forma_de_pagamento	troco	observacoes	cliente	createdAt	updatedAt
        <>
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange}/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row center w100 h5 b27'>
                                <label className='w100'>Selecionar filtro</label>
                                <select name="status" onChange={onChangeSelect}>
                                    <option value=''>Todos</option>
                                    <option value="1"> Aberto</option>
                                    <option value="2"> Em produção</option>
                                    <option value="3"> Finalizado</option>
                                    <option value="4"> Cancelado</option>
                                </select>
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}




