import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminProduto(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Icones',
        mainstring:'nome',
        url:'/api/imagem/',
        url2:'',
        search:'/api/imagem/search/',
        urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '',status: '1', patch:'',id_imagem:''
    }
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)})
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
            
        })
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV filter ou desestruturação
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
            axios[values.id_imagem?'put':'post'](config.url,values).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
    }
    const editar = function(id){//
        setOpenNew(true)
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data[0])
            })
    }
    const exclui = function(id){
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    //console.log(data)
    //COMPONENTES DE LOOP==============================
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_imagem}> 
                <div className='w5'>{namesString.status===1?<img src={IconChecked}/>:<img src={IconNoChecked}/>}</div>
                <img src={namesString.patch} alt={namesString.nome} className="w5"></img>
                <div className='w80 cell-tamanho80 fb'>{namesString.nome}</div>
                <div className='w90 cell-texto-p c4'>{namesString.patch}</div>
                <div className="w5 h4 row center pp">
                    {tenancylocal==='starburguer'&&
                    <b onClick={()=>editar(namesString.id_imagem)}> <img src={IconEdit}></img> </b>
                    }
                </div>
                <div className='w100 fs08em cell-tamanho cell-margin5 c4'>{namesString.ingredientes}</div>
            </div>
    )
   
    return(
        <>
        {openNew&&           
            <div className='w100 h100 posifixedinicio column center z4' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.idproduto?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div>
                                <label>Nome:</label>
                                <input type="text"  name="nome"  onChange={onChange} value={values.nome} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>Patch/Link de icones da internet:</label>
                                <input type="text"  name="patch"  onChange={onChange} value={values.patch}></input>
                            </div>
                            {/*
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>status:</label>
                                    <input type="text"  name="status"  onChange={onChange} value={values.status}></input>
                                </div>
                            </div>
                            */}
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_imagem ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            {values.id_imagem?<button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_imagem)}>Excluir</button>:<label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular ">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className='w90 row center ' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w20 h4 b10 c3 cont-circ8 row center pp cell-text-smile08' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
                               
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>{//8191206077
                }
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

/**
 

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function PaginationExample() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`/imagem?page=${page}&limit=10`);
      const jsonData = response.data;
      setData(jsonData.data);
      setTotalPages(jsonData.totalPages);
    }
    fetchData();
  }, [page]);

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  return (
    <div>
      <h1>Imagens:</h1>
      <ul>
        {data.map(imagem => (
          <li key={imagem.id}>{imagem.nome}</li>
        ))}
      </ul>
      <div>
        <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
          Anterior
        </button>
        <span>{`Página ${page} de ${totalPages}`}</span>
        <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
          Próxima
        </button>
      </div>
    </div>
  );
}


 
 */