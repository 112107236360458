import { random } from "lodash"
import { useEffect, useState } from "react";

import React, { useRef } from 'react';

//import htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';

import * as htmlToImage from 'html-to-image';


import imgBkg0 from'../../../acets/imgRamdonStorie/1.png';
import imgBkg1 from'../../../acets/imgRamdonStorie/2.png';
import axios from "axios";

import TenancyColor from "../../midllers/TenancyColor";

export default function GerarStoryInstagram(){
  const tenancylocal = localStorage.getItem('ClienteTenancy')
  useEffect(()=>{
    axios.get('api/produto/').then(resp=>
      setData(resp.data)
      //console.log(resp.data)
    )
    
  },[])

    const [ranDomText,setRanDomText] = useState(0)
    const [ranDomImage,setRanDomImage] = useState(0)
    const [data, setData] = useState([])
    const [imageRandon,setImageRandon]=useState(0)
    const [tituloRandon,setTituloRandon]=useState(0)

    const divRef = useRef(null);

    const arrydetextos=[
        "Que tal experimentar um hambúrguer delicioso hoje?",
        "Não deixe a fome esperar, peça seu fastfood agora!",
        "O melhor fastfood da cidade está aqui, não perca a oportunidade de provar!",
        "Sinta o sabor inigualável do nosso frango frito crocante!",
        "Nada melhor do que um fastfood para saciar a fome em minutos!",
        "Escolha o seu combo favorito e receba em casa em poucos minutos!",
        "Deixe o fastfood ser a solução para o seu almoço de hoje!",
        "Nosso cardápio de fastfood é de dar água na boca!",
        "Não perca tempo cozinhando, peça já seu fastfood!",
        "Só quem já provou nosso fastfood sabe o que é comida de verdade!",
        "Seu paladar merece o sabor incrível do nosso fastfood!",
        "Sabe aquele fastfood irresistível? Está aqui, só esperando por você!",
        "Fastfood não precisa ser sinônimo de comida ruim, experimente o nosso e surpreenda-se!",
        "A praticidade do fastfood delivery é a melhor opção para sua refeição!",
        "Sem tempo para cozinhar? Não tem problema, nosso fastfood entrega a solução!",
        "Comida boa, rápida e prática é só com nosso fastfood delivery!",
        "Procurando uma opção rápida e saborosa? O fastfood delivery é a resposta!",
        "Não precisa sair de casa para comer bem, peça já seu fastfood delivery!",
        "Satisfaça sua fome com um fastfood delicioso e prático!",
        "Por que complicar a vida com cozinha, se você pode ter nosso fastfood delivery em casa?"
    ];
    const arrydetitulos=[
        "Delivery",
        "Promoção",
        "Especial do dia",
        "Promoção de hoje!",
        "Não perca nossas ofertas!",
        "Sabores incríveis te esperam!",
        "Venha conferir nossas novidades!",
        "Deliciosos pratos para você!",
        "Coma bem, sinta-se bem!",
        "Sua refeição em casa!",
        "Aproveite nossas promoções!",
        "Desfrute do melhor da nossa cozinha!",
        "Coma com prazer e saúde!",
        "Conheça nossos pratos especiais!",
        "Sabor sem igual!",
        "Comida fresquinha e saudável!",
        "Faça seu pedido agora!",
        "Receba em casa com rapidez!",
        "Seu jantar especial!",
        "Mais sabor para o seu dia!",
        "Experimente nossa gastronomia!",
        "Qualidade garantida!",
        "Sabores inesquecíveis!"
    ];
    function gerarNumeroAleatorio() {
        setRanDomText(Math.floor(Math.random() * 20) + 0)
      }
    function gerarimagemAleatorio() {
        setRanDomImage(Math.floor(Math.random() * data.length) + 0)
       // console.log(data[ranDomImage].nome)
       //
       //console.log(ranDomImage)
      }
    function gerarimagemAleatorio2() {
        setImageRandon(Math.floor(Math.random() * 2) + 0)
      }
    function gerartituloAleatorio() {
        setTituloRandon(Math.floor(Math.random() * 23) + 0)
      }
    const arrayImage = [imgBkg0,imgBkg1]
    
    const styleinlinelocal = {
        backgroundImage: 'url('+arrayImage[imageRandon]+')',
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      };

      const handleConvertToImage = () => { //CHAT GPT QUE CRIOU
        htmlToImage.toPng(divRef.current)
          .then(function (dataUrl) {
            const img = new Image();
            img.src = dataUrl;
            document.getElementById("resultImg").appendChild(img);
          })
          .catch(function (error) {
            console.error('Oops, something went wrong!', error);
          });
      };
//console.log(data)

    return(
      <>
        <div className="b1 h100 w100 row center">
            <div ref={divRef} className="w100 h90 wmax600 column center" style={styleinlinelocal} >
                <h1 className="w90 h10">{arrydetitulos[tituloRandon]}</h1>
                <h5 className="w90 c3">{arrydetextos[ranDomText]}</h5>
                <div className="row center w90">
                    <h4 className="c3 w100">
                      {ranDomImage&&
                        data[ranDomImage].nome
                        }
                    </h4>
                    <h5 className="fb c3">R$:</h5>
                    <h1>
                    {ranDomImage&&
                        data[ranDomImage].preco_final.toFixed(2)//id_tenancy
                        }
                    </h1>
                </div>
                <div className="b10 w90 h10 cont-circ column center sombra" style={{backgroundColor: TenancyColor().backgroundColor}}>
                    <label className="fb c1" >Peça agora mesmo!</label>
                    <label className="fs08em c2 ma10b0">www.cardapio360.com.br/loja/{tenancylocal}</label>
                </div>
            </div>
            <div className="column w8 h100 center posifixedfim20 rigth0 cell-tamanho20">
                <button className="w100 ma5 p20 fs08em" onClick={()=>gerartituloAleatorio()} style={{backgroundColor: TenancyColor().backgroundColor}}>
                  Titulo</button>
                <button className="w100 ma5 p20 fs08em" onClick={()=>gerarNumeroAleatorio()} style={{backgroundColor: TenancyColor().backgroundColor}}>
                  Texto</button>
                <button className="w100 ma5 p20 fs08em" onClick={()=>gerarimagemAleatorio2()} style={{backgroundColor: TenancyColor().backgroundColor}}>
                  Imagem</button>
                <button className="w100 ma5 p20 fs08em" onClick={()=>gerarimagemAleatorio()} style={{backgroundColor: TenancyColor().backgroundColor}}>
                  Produto</button>
                <button className="w100 ma5 p20 fs08em "onClick={handleConvertToImage} style={{backgroundColor: TenancyColor().backgroundColor}}>
                  Export</button>
            </div>
        </div>
        <div id="resultImg" className="b1 w100 center column">

        </div>
      </>
    )
}

