import  { useEffect,useState } from 'react'
import axios from "axios";
import IconLixeira from '../../acets/img_icon/lixo.svg'
import OpemDetaisPedido from '../../acets/img_icon/IconOpemDetailsPedido.svg'
import IcoBin from '../../acets/img_icon/bin.svg'


export default function ItensPedido(props){
    const [openModalEcluir,setOpenModalEcluir]=useState(false)
    const [idEcluir,setidExluir]=useState(false)
    const [openSucess,setOpenSucess]=useState(false)
    const [dataItens, setDataItens] = useState([])

    useEffect(()=>{
      rec();
    },[props.id])
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
      
        //setCountSt(count);     rt
    }
    
    //console.log(dataItens)
    const exclui = function(id){//EXCLUI
      //console.log("aqui")
        axios.delete('/api/pivo_pedido_cliente/' + id).then(resp=>{
          axios.get('/api/pivo_pedido_cliente/'+props.id).then(resp=>{
            setDataItens(resp.data)
          })
        })
    }
  
    function opemdetails(id){
      axios.get('/api/pivo_pedido_cliente/'+id).then(resp=>{
        //console.log(resp.data)
        setDataItens(resp.data)
        setOpenSucess(true)
        }) 
    }
    //console.log(dataItens)
    var total = dataItens.reduce(getTotal, 0);//PERCORRENDO o array para somar o total dos itens
    function getTotal(total, item) {//PERCORRE
      return total + (item.preco_final);
    }
   //console.log(dataItens)



   const final = dataItens.map(namesString => {
    const opcionaisObj = JSON.parse(namesString.opcionais);
    let precoTotalProduto = namesString.preco_final;
    const opcionaisSelecionados = [];
  
    for (const opcional in opcionaisObj) {
      const checked = opcionaisObj[opcional].checked;
      const precoOpcional = opcionaisObj[opcional].preco_opcional;
  
      if (checked) {
        precoTotalProduto += precoOpcional;
        opcionaisSelecionados.push(opcional);
      }
    }
  
    return (
      <div className='w100 h5 row between linha c4 fs06em' key={namesString.id_pivo}>
        <label>{namesString.quantidade} -</label>
        <label className='w50 fw500'>{namesString.produto.nome}</label>
        <label>R$: {precoTotalProduto.toFixed(2)}</label>
  
        <img src={IcoBin} alt="lixeira" className='pp w3 fim'
          onClick={() => {
            setidExluir(namesString.id_pivo);
            setOpenModalEcluir(true);
          }}
        />
  
        {opcionaisSelecionados.length > 0 && (
          <div className='w100 column'>
            <label className='w80 b27'>{opcionaisSelecionados.join(',')}</label>
            {/* Aqui você pode exibir detalhes dos opcionais se necessário */}
          </div>
        )}
      </div>
    );
  });

  
 // Cálculo do preço total de todos os itens
const precoTotal = dataItens.reduce((total, item) => {
  const opcionaisObj = JSON.parse(item.opcionais);
  let precoTotalProduto = item.preco_final;

  for (const opcional in opcionaisObj) {
    const checked = opcionaisObj[opcional].checked;
    const precoOpcional = opcionaisObj[opcional].preco_opcional;

    if (checked && !isNaN(precoOpcional)) {
      precoTotalProduto += precoOpcional;
    }
  }

  return total + precoTotalProduto;
}, 0);
  
  // Exibição do preço total de todos os itens
  const precoTotalString = precoTotal.toFixed(2);
    
    // Utilize a variável precoTotalString onde você precisa exibir o preço total de todos os itens.


    //{final} <label className='w80 b27'>{Object.keys(JSON.parse(namesString.opcionais)).join(',')}</label>
    return(
      <div className='w100 row between'>
        {openModalEcluir&&
              <div className='w100 h100 fundo-opaco posifixedinicio left0 column center'>
                <h5 className='fb c3 cen ma25b0'>Deseja excluir permanentemente?</h5>
                <div className='row'>
                  <button className='ml10' onClick={()=>{exclui(idEcluir);setOpenModalEcluir(false)}}>Confirmar</button>
                  <button className='ml10 btn05' onClick={()=>setOpenModalEcluir(false)}>Cancelar</button>
                </div>
               
              </div>
        }
        <div className='w100 column'>
            {!openSucess&&
            <img src={OpemDetaisPedido} className='w8 column center cell-tamanho20 pp' onClick={()=>opemdetails(props.id)}></img>
            }
        </div>
        
        {final}
        {dataItens[0]?.pedido.frete&&<label className='fs08em w100 ma10b0'>Frete R$:{dataItens[0].pedido.frete}</label>}
        <label className='fb fs1em'>Total</label>
        <label className='fb fs1em'>R$: {parseFloat(precoTotalString) + (dataItens[0]?.pedido.frete || 0)}</label>

      </div>
    )
}