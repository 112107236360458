/*import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import axios from 'axios';

function Receita() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/api/pivo_pedido_cliente/').then(resp => {
      setData(resp.data);
    });
  }, []);

  const handleChange = (event) => {
    const month = event.target.value;
    const filteredData = data.filter(item => {
      const date = new Date(item.pedido.createdAt);
      return date.getMonth() === month;
    });
    setData(filteredData);
  };

  return (
    <div>
      <select onChange={handleChange}>
        <option value="4">Maio</option>
        <option value="5">Junho</option>
      </select>
      <ChartReceita data={data} />
    </div>
  );
}

function ChartReceita(props) {
  const data = [['Mês', 'Receita']];
  const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const receitas = {};

  props.data.forEach(item => {
    const date = new Date(item.pedido.createdAt);
    const month = date.getMonth();
    const revenue = item.quantidade * item.produto.preco_final;

    if (receitas[month]) {
      receitas[month] += revenue;
    } else {
      receitas[month] = revenue;
    }
  });

  for (let i = 0; i < 12; i++) {
    const monthData = [meses[i], receitas[i] || 0];
    data.push(monthData);
  }

  return (
    <Chart
      chartType="LineChart"
      data={data}
      options={{
        title: 'Receita por mês',
        hAxis: {
          title: 'Mês',
        },
        vAxis: {
          title: 'Receita',
        },
      }}
      width="100%"
      height="400px"
      legendToggle
    />
  );
}

export default Receita;*/





/*
======================================================================


import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';

const BarChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/api/pivo_pedido_cliente/').then(resp => setData(resp.data));
  }, []);

  const chartData = [['Mês', 'Vendas']];
  const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const getMonthData = (month) => {
    let total = 0;
    data.forEach(item => {
      const monthNumber = new Date(item.createdAt).getMonth();
      if (monthNumber === month) {
        total += item.produto.preco_final;
      }
    });
    return total;
  }

  for (let i = 0; i < 12; i++) {
    chartData.push([monthNames[i], getMonthData(i)]);
  }

  return (
    <Chart className='w100 b5'
      width={'500px'}
      height={'300px'}
      chartType="BarChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        title: 'Vendas por mês',
        chartArea: { width: '50%' },
        hAxis: {
          title: 'Mês',
          minValue: 0,
        },
        vAxis: {
          title: 'Vendas',
        },
      }}
      legendToggle
    />
  );
}

export default BarChart;*/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';

function GraficoDeVendas() {
  const [data, setData] = useState([]);

  const januaryFirst = new Date(new Date().getFullYear(), 0, 1);
  const [startDate, setStartDate] = useState(januaryFirst.toISOString().substr(0, 10) + "T00:00");

  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10) + "T23:59");

  useEffect(() => {
    axios.get('/api/pivo_pedido_cliente/')
      .then(resp => {
        setData(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function calcularVendasPorDia() {
    const vendasPorDia = {};

    data.forEach(pedido => {
      const dataPedido = pedido.createdAt.substr(0, 10);

      if (dataPedido >= startDate.substr(0, 10) && dataPedido <= endDate.substr(0, 10)) {
        const precoFinal = parseFloat(pedido.produto.preco_final);

        if (vendasPorDia[dataPedido]) {
          vendasPorDia[dataPedido] += precoFinal;
        } else {
          vendasPorDia[dataPedido] = precoFinal;
        }
      }
    });

    return vendasPorDia;
  }

  function gerarDadosDoGrafico() {
    const vendasPorDia = calcularVendasPorDia();
    const dadosDoGrafico = [['Dia', 'Venda R$']];

    Object.keys(vendasPorDia).forEach(dia => {
      dadosDoGrafico.push([dia, vendasPorDia[dia]]);
    });

    return dadosDoGrafico;
  }

  const options = {
    title: 'Vendas diárias',
    curveType: 'function',
    legend: { position: 'bottom' },
  };

  return (
    <div className='w90'>
       <h5>Faturamento em R$</h5>
      <label>Data de Inicio:</label>
      <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value + "T00:00")} />
      <label>Data fim:</label>
      <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value + "T23:59")} />
      <Chart 
        chartType="LineChart"
        data={gerarDadosDoGrafico()}
        options={options}
        width="100%"
        height="400px"
      />
    </div>
  );
}

export default GraficoDeVendas;