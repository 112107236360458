import axios from "axios";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import { Chart } from "react-google-charts";


import TenancyColor from "../midllers/TenancyColor";
import { useNavigate } from 'react-router-dom';

import ChartPedidosMes from'../partils/ChartPedidosMes'
import ChartVisitasMes from'../partils/ChartVisitasMes'
import ChartProdutosMaisVendidos from '../partils/ChartProdutosMaisVendidos'
import ChartCampanhaInstagram from '../partils/ChartCampanhaInstagram'
import ChartReceitaMes from'../partils/ChartReceitaMes'


import AdminHeader from "../partils/AdminHeader";

export default function Painel(){
   const navigate = useNavigate();
   const tlocal = localStorage.getItem('keyItemNameg')
   axios.defaults.headers.common['token'] = tlocal;
   
   const [produto,setProduto]=useState(0)
   const [pedido,setPedido]=useState(0)
   const [feedback,setFeedback]=useState(0)
   const [faturamento,setFaturamento]=useState(0)
   const [data,setData]=useState()
   const [checkout,setCheckout]=useState([])
   const [fatTotal,setFatTotal]=useState(0)

   const tenancylocal = localStorage.getItem('ClienteTenancy')

   useEffect(()=>{
      rec();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
      function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES 
         axios.get('/api/empresa/tenancy/one/' + tenancylocal)
            .then(resp => {
              const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
            })
            .catch(error => {
              console.log(error);
              return <div>{error.message || error.toString()}</div>; // renderiza a mensagem de erro como uma string
            });
         axios.get('/api/dashboard/cont/produto').then(resp=>{
            setProduto(resp.data)
            })
         axios.get('/api/pedido').then(resp=>{
            setPedido(resp.data)
            })
         axios.get('/api/cliente').then(resp=>{
            setCheckout(resp.data)
            })  
         axios.get('/api/pivo_pedido_cliente/').then(resp =>{
             setFatTotal(resp.data)
            })
         .catch(err => console.log(err));
        
     }
    //console.log(pedido[0])
    
    const totalGanho = fatTotal&&fatTotal.reduce((acc, item) => {
     return acc + item.produto.preco_final;
    }, 0);
  
     //style={{backgroundColor: TenancyColor().backgroundColor}}
    return(
        <section className='h90 column center b15 b30'>
         
            <AdminHeader props='Dashboard'/>
            <div className="h20 w90 posiabstop row between aligitensstart">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5" >
                       <label className=" ma10b0 " >Produtos</label>
                       <label className=" fb h10 column center">{produto}</label>
                    </div>
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                       <label className=" ma10b0">Pedidos Total</label>
                       <label className=" fb h10 column center">{pedido.length}</label>
                    </div>
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                       <label className=" ma10b0">Checkout</label>
                       <label className=" fb h10 column center">{checkout.length}</label>
                    </div>
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                       <label className=" ma10b0">Conversão</label>
                       <label className=" fb h10 column center">{((pedido.length/checkout.length)*100).toFixed(2)}%</label>
                    </div>
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                       <label className=" ma10b0">Faturamento</label>
                       <label className=" fb fs08em h10 column center">R$ {totalGanho.toFixed(2)}</label>
                    </div>
                    
                    <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                       <label className=" ma10b0">T.Visitantes</label>
                       <label className=" fb h10 column center">-</label>
                    </div>
                    
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                     {pedido&&
                        <ChartPedidosMes pedidos={pedido}/>
                     } 
                    </div>
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                     {pedido&&
                        <ChartReceitaMes/>
                     } 
                    </div>
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                     {pedido&&
                        <ChartProdutosMaisVendidos/>
                     } 
                    </div>
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                     {pedido&&
                        <ChartVisitasMes/>
                     } 
                    </div>
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                     {pedido&&
                        <ChartCampanhaInstagram/>
                     } 
                    </div>
                    
                    
                </div>
                
               

            </div>
            <div className="h100 w100 b17">
               
            </div>
      </section>
    )
}
//67982113560 itau
